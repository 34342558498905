import React from "react";
import ReactDOM from "react-dom";

// Styles
import "bootstrap/dist/css/bootstrap.css";
import "react-toastify/dist/ReactToastify.css";
import "react-tagsinput/react-tagsinput.css";
import "./assets/css/animate.css";
import "./assets/css/quill.snow.css";
import "./assets/css/style.css";

import App from "./App";
import { FirebaseProvider } from "./contexts";
// import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <FirebaseProvider>
    <App />
  </FirebaseProvider>,
  document.getElementById("root")
);
// reportWebVitals();
