import React, { useContext, useEffect, useCallback, useState } from "react";
import { FirebaseContext } from "../contexts";
import { Button, Container } from "reactstrap";
import { freelancerService, toastAlert } from "../Services";
// Core Components
import Header from "../components/Layout/Header";
import SidebarMenu from "../components/dashboard/SidebarMenu";
import Subscribe from "../components/dashboard/Subscribe";
import Freelancer from "../components/dashboard/Freelancer";

const Dashboard = () => {
  const { firebase, setLoading } = useContext(FirebaseContext);
  const [freelancerData, setFreelancerData] = useState(null);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [sidebarMenuOpen, setSidebarMenuOpen] = useState(false);
  const toggleSidebarMenu = () => setSidebarMenuOpen(!sidebarMenuOpen);

  const getDashboardData = useCallback(async () => {
    try {
      let idToken = await firebase.auth.currentUser.getIdToken(true);
      let data = await freelancerService.getMyData({ idToken });
      setFreelancerData(data.freelancerData);
      setIsSubscribed(data.isSubscribed);
      setLoading(false);
    } catch (error) {
      setFreelancerData({
        uid: '',
        image: {},
        active: false,
        firstName: '',
        lastName: '',
        profile: '',
        location: '',
        about: '',
        price: 0,
        currency: 'usd',
        yearsOfExperience: '',
        languages: [],
        networks: [],
        skills: [],
        experiences: [],
        portfolios: []
      });
      setLoading(false);
      toastAlert("error", error.message || "Error while fetching user data!");
    }
  }, [firebase, setLoading]);

  useEffect(() => {
    document.body.classList.add("white-bg", "dashboard");
    getDashboardData();
  }, [getDashboardData]);

  useEffect(() => {
    return () => {
      document.body.classList.remove("white-bg", "dashboard");
      setLoading(true);
    }
  }, [setLoading]);

  return (
    <>
      <Header />
      <section>
        <div className="dashboard">
          <div id="wrapper" className={sidebarMenuOpen ? "toggled" : ""}>
            <SidebarMenu toggleSidebarMenu={toggleSidebarMenu} />
            <div id="page-content-wrapper">
              <Container>
                <nav className="navbar mt-2">
                  <Button
                    id="menu-toggle"
                    onClick={toggleSidebarMenu}
                    color="primary"
                    className="navbar-toggler ml-n3 border-0 bg-transparent text-dark shadow-none"
                  >
                    <i className="fas fa-bars text-dak"></i>
                  </Button>
                </nav>
                {isSubscribed ? (
                  <Freelancer data={freelancerData} />
                ) : (
                  <Subscribe />
                )}
              </Container>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Dashboard;
