import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import firebaseConfig from "./FirebaseConfig";


class Firebase {
  static firebase;
  constructor() {
    app.initializeApp(firebaseConfig);
    this.auth = app.auth();
  }

  static getInstance() {
    if (!Firebase.firebase) {
      Firebase.firebase = new Firebase();
    }
    return Firebase.firebase;
  }

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);

  doSendEmailVerification = async (user) => {
    await user && user.sendEmailVerification();
    return user;
  }

  doUpdateDisplayName = async (user, displayName) => {
    await user && user.updateProfile({
      displayName
    });
    return user;
  }

  doSignOut = () => this.auth.signOut();
}

let firebase = Firebase.getInstance();
export default firebase;