import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { FirebaseContext } from '../contexts';
import Loader from './Layout/Loader';


const PrivateRoute = ({ component: Component, redirectTo, ...rest }) => {
  const { user, authenticated } = useContext(FirebaseContext);
  if(!authenticated){
    return <Loader/>
  }
  if(authenticated){
    return (
      <Route
        {...rest}
        render={routeProps =>
          user ? (
            <Component {...routeProps} />
          ) : (
            <Redirect to={redirectTo || '/login'} />
          )
        }
      />
    );
  }
};

export {
  PrivateRoute
};