import React, { useState, useContext, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FirebaseContext } from '../contexts';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  FormFeedback
} from 'reactstrap';
// Core Components
import Header from '../components/Layout/Header';
import {
  toastAlert
} from '../Services';


const Login = () => {
  const { firebase, setUser, setLoading } = useContext(FirebaseContext);
  const history = useHistory();
  const [formValues, setFormValues] = useState({
    email: '',
    password: '',
  });
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    (async function () {
      firebase.auth.onAuthStateChanged(async (user) => {
        if (user && user.emailVerified) {
          history.push('/');
          return;
        } else {
          setLoading(false);
        }
      });
    })();
    document.body.classList.add('white-bg');
  }, [setLoading, history, firebase.auth]);

  useEffect(() => {
    return () => {
      document.body.classList.remove('white-bg');
    }
  }, []);

  const handleChange = (event) => {
    // event.persist();
    event.preventDefault();
    let { name, value } = event.target;
    value = value.trim();

    let errors = { ...formErrors };
    const validEmailRegex = RegExp(/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/i);

    switch (name) {
      case 'email':
        errors[name] = !validEmailRegex.test(value) ? 'Email is not valid!' : '';
        break;
      case 'password':
        errors[name] = value.length < 1 ? 'Password is not valid!' : '';
        break;
      default:
        break;
    }
    setFormValues(values => ({ ...values, [name]: value }));
    setFormErrors(_ => ({ ...errors }));
  }

  const validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach(
      (val) => val.length > 0 && (valid = false)
    );
    return valid;
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm(formErrors)) {
      firebase.doSignInWithEmailAndPassword(formValues.email, formValues.password)
        .then(resUser => {
          if (!resUser.user.emailVerified) {
            toastAlert('info', 'Please verify your email address to login');
            firebase.doSignOut();
            return;
          }
          setUser(resUser.user);
          history.push('/');
        }).catch(error => {
          toastAlert('error', error.message);
        });
    }
  }

  return (
    <div className="header-nav-bg">
      <Header />
      <section>
        <div className="signup-section login-section">
          <div className="text-center wow fadeInUp">
            <h1 className="main-title">Hello</h1>
            <Container className="form-section mt-4">
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col sm="12" className="mb-4">
                    <FormGroup className="mb-0">
                      <Input
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        value={formValues.email}
                        onChange={handleChange}
                        invalid={!!formErrors.email}
                        required
                      />
                      <FormFeedback invalid={formErrors.email}>{formErrors.email}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm="12" className="mb-3">
                    <FormGroup className="mb-0">
                      <Input
                        type="password"
                        name="password"
                        className="form-control"
                        placeholder="Password"
                        value={formValues.password}
                        onChange={handleChange}
                        invalid={!!formErrors.password}
                        required
                      />
                      <FormFeedback invalid={formErrors.password}>{formErrors.password}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm="12">
                    <p className="forgot-password">
                      <Link to="/forgot-password">Forgot password ?</Link>
                    </p>
                  </Col>
                </Row>
                <Button type="submit" className="btn-next shadow-none mt-3">
                  Log in
                </Button>
                <p className="ac-text mt-4">I don't have an account <Link to="/sign-up"> Sign Up </Link></p>
              </Form>
            </Container>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Login;