import React, { useState, useRef, useContext, useEffect } from 'react';
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import moment from 'moment';
import Select from 'react-select';
import TagsInput from 'react-tagsinput';
import Slider, { createSliderWithTooltip } from 'rc-slider';
import { FirebaseContext } from '../../contexts';
import {
  devProfileList,
  countryList,
  languageList,
  langProficiencyList,
} from '../../Services/FreelancerHelper';
import {
  freelancerService,
  toastAlert
} from '../../Services';
import ImgUploadModal from '../../components/common/ImageUpload';
import 'rc-slider/assets/index.css';
const Range = createSliderWithTooltip(Slider.Range);


const Freelancer = ({ data }) => {
  const { firebase, setLoading } = useContext(FirebaseContext);
  const flImageRef = useRef(null);
  const pfImageRef = useRef(null);
  const [defaultData, setDefaultData] = useState({ ...JSON.parse(JSON.stringify(data)) });
  const [flData, setFreelancerData] = useState({ ...JSON.parse(JSON.stringify(data)) });
  const [imgUploadModals, setImgUploadModals] = useState([]);

  const newPFData = {
    name: '',
    image: { data: '', contentType: '' },
    description: ''
  };

  const onChangeProfilePic = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        displayImgUploadModal(reader, uploadUserImg, cancelUserImgUpload);
      });
      reader.readAsDataURL(e.target.files[0]);
    };
  };

  const displayImgUploadModal = (reader, uploadImageFunc, cancelUploadFunc) => {
    setImgUploadModals([
      <ImgUploadModal
        key={Math.random()}
        showModal={true}
        imgSrc={reader.result}
        imgRef={false}
        uploadImage={uploadImageFunc}
        cancelUpload={cancelUploadFunc}
        imgCrop={{ unit: 'px', width: 240, height: 170 }}
      />
    ]);
  }

  const uploadUserImg = (image) => {
    setFreelancerData({ ...flData, 'image': image });
  }

  const cancelUserImgUpload = () => {
    setImgUploadModals([]);
    document.getElementById('imgupload').value = null;
  }

  const [pfIndex, setPFIndex] = useState(null);
  const [pfFormData, setPFFormData] = useState(newPFData);

  const [showPFModal, setShowPFModal] = useState(false);
  const closePFModal = () => {
    setPFIndex(null);
    setPFFormData(newPFData);
    setShowPFModal(false);
  }

  const experienceMarks = {
    0: {
      style: {
        color: "var(--main-blue)",
      },
      label: <strong>0</strong>,
    },
    1: {
      style: {
        color: "var(--main-blue)",
      },
      label: <strong>1+</strong>,
    },
    2: {
      style: {
        color: "var(--main-blue)",
      },
      label: <strong>2+</strong>,
    },
    3: {
      style: {
        color: "var(--main-blue)",
      },
      label: <strong>3+</strong>,
    },
    4: {
      style: {
        color: "var(--main-blue)",
      },
      label: <strong>4+</strong>,
    },
    5: {
      style: {
        color: "var(--main-blue)",
      },
      label: <strong>5+</strong>,
    },
    6: {
      style: {
        color: "var(--main-blue)",
      },
      label: <strong>6+</strong>,
    },
    7: {
      style: {
        color: "var(--main-blue)",
      },
      label: <strong>7+</strong>,
    },
    8: {
      style: {
        color: "var(--main-blue)",
      },
      label: <strong>8+</strong>,
    },
    9: {
      style: {
        color: "var(--main-blue)",
      },
      label: <strong>9+</strong>,
    },
    10: {
      style: {
        color: "var(--main-blue)",
      },
      label: <strong>10+</strong>,
    },
  };

  const isValidData = () => {
    let data = { ...flData };
    if (!data.image) {
      throw new Error('Please upload your profile picture!');
    }
    if (!data.firstName) {
      throw new Error('First name is required!');
    }
    if (!data.lastName) {
      throw new Error('Last name is required!');
    }
    if (!data.profile) {
      throw new Error('Profile selection is required!');
    }
    if (!data.location) {
      throw new Error('Country selection is required!');
    }
    if (!data.about) {
      throw new Error('About info is required!');
    }
    if (!data.price) {
      throw new Error('Price by days is required!');
    }
    if (!data.currency) {
      throw new Error('Currency selection is required!');
    }
    if (data.experiences.length) {
      data.experiences.map((exp) => {
        if (!exp.name) {
          throw new Error('Company name is required!');
        }
        if (!exp.startDate) {
          throw new Error('Experience start date is required!');
        }
        if (!exp.isPresent && !exp.endDate) {
          throw new Error('Experience end date is required if it is not present company!');
        }
        if (!exp.post) {
          throw new Error('Experience post/designation is required!');
        }
        if (!exp.description) {
          throw new Error('Experience description is required!');
        }
        return exp;
      });
    }
    if (data.portfolios.length) {
      data.portfolios.map((pf) => {
        if (!pf.name) {
          throw new Error('Project name is required!');
        }
        if (!pf.description) {
          throw new Error('Project description is required!');
        }
        return pf;
      });
    }
    return;
  }

  const getFreelancerData = async () => {
    let data = { ...flData };
    let idToken = await firebase.auth.currentUser.getIdToken(true);
    data['idToken'] = idToken;
    delete data.uid;
    return data;
  }

  const updateProfile = async () => {
    try {
      isValidData();
      setLoading(true);
      let data = await getFreelancerData();
      let responseData = await freelancerService.updateData(data);
      setDefaultData(responseData);
      setFreelancerData(responseData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toastAlert('error', error.message || 'Invalid profile data!');
      return;
    }
  }

  const uploadPFImage = (event) => {
    const imageFile = event.target.files && event.target.files[0];
    if (imageFile) {
      const contentType = imageFile.type;
      const reader = new FileReader();
      reader.onload = (e) => {
        const image = {
          'data': e.target.result.split('base64,')[1],
          'contentType': contentType
        }
        const pfFormDataCopy = { ...pfFormData };
        pfFormDataCopy['image'] = image;
        setPFFormData(pfFormDataCopy);
      }
      reader.readAsDataURL(imageFile);
    }
  }

  const triggerFLImageFile = () => {
    flImageRef.current.click();
  }

  const triggerPFImageFile = () => {
    pfImageRef.current.click();
  }

  const setActive = (active) => {
    setFreelancerData({ ...flData, active });
  }

  const setFirstName = (event) => {
    const firstName = event.target.value.trim();
    setFreelancerData({ ...flData, firstName });
  }

  const setLastName = (event) => {
    const lastName = event.target.value.trim();
    setFreelancerData({ ...flData, lastName });
  }

  const setProfile = (profile) => {
    setFreelancerData({ ...flData, 'profile': profile });
  }

  const setLocation = (location) => {
    setFreelancerData({ ...flData, 'location': location });
  }

  const setAbout = (event) => {
    const about = event.target.value;
    setFreelancerData({ ...flData, about });
  }

  const setPrice = (event) => {
    let price = event.target.value;
    price = Number(price) ? Number(price).toString() : '0';
    setFreelancerData({ ...flData, price });
  }

  const setCurrency = (currency) => {
    setFreelancerData({ ...flData, currency });
  };

  const setAvailability = () => {
    let availability = [...document.querySelectorAll(`input[name=availability]:checked`)].map(e => e.value);
    setFreelancerData({ ...flData, availability });
  }

  const setWorkplace = () => {
    let workplace = [...document.querySelectorAll(`input[name=workplace]:checked`)].map(e => e.value);
    setFreelancerData({ ...flData, workplace });
  }

  const setYearsOfExperience = (yearsOfExperience) => {
    setFreelancerData({ ...flData, 'yearsOfExperience': yearsOfExperience[0] || 0 });
  }

  const deleteLanguage = (index) => {
    const languagesCopy = [...flData.languages];
    languagesCopy.splice(index, 1);
    setFreelancerData({ ...flData, 'languages': languagesCopy });
  }

  const updateLanguage = (index, key, value) => {
    const languagesCopy = [...flData.languages];
    languagesCopy[index][key] = value;
    setFreelancerData({ ...flData, 'languages': languagesCopy });
  }

  const addNewLanguage = () => {
    const languagesCopy = [...flData.languages];
    languagesCopy.push({ lang: '', proficiency: '' });
    setFreelancerData({ ...flData, 'languages': languagesCopy });
  }

  const setNetwork = (event, website) => {
    const url = event.target.value.trim();
    let networksCopy = [...flData['networks']];
    const index = networksCopy.findIndex(net => net.website === website);
    if (index !== -1) {
      if (url) {
        networksCopy[index]['url'] = url;
      } else {
        networksCopy = networksCopy.filter(net => net.website !== website);
      }
    } else {
      networksCopy.push({ website, url });
    }
    setFreelancerData({ ...flData, 'networks': networksCopy });
  };

  const handleSkills = (skills) => {
    const uniqueSkills = Array.from(new Set(skills));
    setFreelancerData({ ...flData, 'skills': uniqueSkills });
  };

  const addNewExperience = () => {
    const expCopy = [...flData.experiences];
    expCopy.push({ name: '', startDate: '', endDate: '', isPresent: false, post: '', description: '', technologies: [] });
    setFreelancerData({ ...flData, 'experiences': expCopy });
  }

  const updateExperience = (index, key, value) => {
    const expCopy = [...flData.experiences];
    expCopy[index][key] = value;
    if (key === 'isPresent') {
      expCopy[index]['endDate'] = '';
    }
    setFreelancerData({ ...flData, 'experiences': expCopy });
  }

  const deleteExperience = (index) => {
    const expCopy = [...flData.experiences];
    expCopy.splice(index, 1);
    setFreelancerData({ ...flData, 'experiences': expCopy });
  }

  const updatePFForm = (key, value) => {
    const pfFormDataCopy = { ...pfFormData };
    pfFormDataCopy[key] = value;
    setPFFormData(pfFormDataCopy);
  }

  const showPortfolioData = (index) => {
    let pfData = [...flData.portfolios][index];
    setPFIndex(index);
    setPFFormData(pfData);
    setShowPFModal(true);
  }

  const addNewPortfolio = () => {
    const portfolioCopy = [...flData.portfolios];
    portfolioCopy.push(newPFData);
    const pfIndex = portfolioCopy.length - 1;
    setPFIndex(pfIndex);
    setPFFormData(newPFData);
    setShowPFModal(true);
  }

  const savePortfolio = () => {
    const portfolioCopy = [...flData.portfolios];
    portfolioCopy[pfIndex] = { ...pfFormData };
    setFreelancerData({ ...flData, 'portfolios': portfolioCopy });
    setPFIndex(null);
    setPFFormData(newPFData);
    setShowPFModal(false);
  }

  const deletePortfolio = (event, index) => {
    event.stopPropagation();
    const portfolioCopy = [...flData.portfolios];
    portfolioCopy.splice(index, 1);
    setFreelancerData({ ...flData, 'portfolios': portfolioCopy });
  }

  const cancelChanges = () => {
    setFreelancerData({ ...defaultData });
  }

  return (
    <div className="d-flex page-content-dashboard subscribe-vide">
      <div className="dashboard-subscribe-content w-100">
        <Row className="m-0 p-0">
          <Col sm="12" className="mb-4 dash-head">
            <h2 className="d-inline-block mt-3">Freelance</h2>
            <div className="float-right mt-0">
              <Button
                color="primary"
                className={`bg-transparent shadow-none active-btn ${flData.active ? "" : "deactivate"}`}
                onClick={(e) => setActive(!flData.active)}>
                {flData.active ? "Active" : "Inactive"}
              </Button>
            </div>
          </Col>

          <Col className="d-none">
            {imgUploadModals.map(modal => (
              modal
            ))}
          </Col>

          <Col xl="3">
            <div className="profile-pic-upload text-center">
              {flData.image && flData.image.data ? (
                <img
                  alt=""
                  className="img-fluid rounded-circle"
                  src={`data:${flData.image.contentType};base64,${(flData.image.data)}`}
                />
              ) : (
                <img
                  alt=""
                  className="img-fluid rounded-circle"
                  src={require(`../../assets/images/profile-pic-upload.png`).default}
                />
              )}
            </div>
            <div className="img-upload text-center">
              <Button
                color="primary"
                id="OpenImgUpload"
                className="bg-transparent img-upload-btn shadow-sm mt-2"
                onClick={triggerFLImageFile}
              >Upload
              </Button>
              <Input
                type="file"
                id="imgupload"
                className="d-none"
                innerRef={flImageRef}
                accept="image/x-png,image/webp,image/jpeg"
                onChange={onChangeProfilePic}
              />
            </div>

            <div className="price-by-day">
              <h6>Price by days</h6>
              <div className="price-part">
                <Input
                  type="number"
                  name="price"
                  id="price"
                  min="0"
                  className="price-text-add mr-2"
                  value={flData.price || 0}
                  onChange={(e) => setPrice(e)}
                />
                <div className={`price-symbol mr-2 ${flData.currency === 'usd' ? "active" : ""}`} onClick={() => setCurrency('usd')}>$</div>
                <div className={`price-symbol mr-2 ${flData.currency === 'eur' ? "active" : ""}`} onClick={() => setCurrency('eur')}>€</div>
              </div>
            </div>
          </Col>
          <Col xl="9" className="dashboard-form">
            <Form>
              <Row className="form-row">
                <Col md="6">
                  <FormGroup>
                    <Label for="firstName">First name</Label>
                    <Input
                      type="text"
                      name="firstName"
                      id="firstName"
                      className="form-control"
                      value={flData.firstName}
                      onChange={(e) => setFirstName(e)}
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label for="lastName">Last name</Label>
                    <Input
                      type="text"
                      name="lastName"
                      id="lastName"
                      className="form-control"
                      value={flData.lastName}
                      onChange={(e) => setLastName(e)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row className="form-row">
                <Col md="6">
                  <FormGroup>
                    <Label for="profile">Profile</Label>
                    <Select
                      name="profile"
                      id="profile"
                      className="react-select react-select-primary"
                      classNamePrefix="profile-react-select"
                      defaultValue={{ label: "Select Profile", value: "" }}
                      value={devProfileList.find(p => p.value === flData.profile) || { label: "Select Profile", value: "" }}
                      onChange={(e) => setProfile(e.value)}
                      options={devProfileList}
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label for="location">Country</Label>
                    <Select
                      name="location"
                      id="location"
                      className="react-select react-select-primary"
                      classNamePrefix="profile-react-select"
                      defaultValue={{ label: "Select Country", value: "" }}
                      value={countryList.find(c => c.value === flData.location) || { label: "Select Country", value: "" }}
                      onChange={(e) => setLocation(e.value)}
                      options={countryList}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup>
                <Label for="about">About:</Label>
                <textarea
                  name="about"
                  id="about"
                  rows="3"
                  maxLength="1000"
                  className="form-control dash-text-area"
                  placeholder="Write something about yourself (max: 1000 characters)"
                  value={flData.about}
                  onChange={(e) => setAbout(e)}
                />
              </FormGroup>
            </Form>
          </Col>
        </Row>
        <hr />

        <Row className="justify-content-center m-0 p-0">
          <Col sm="12">
            <h5>More Info</h5>
          </Col>

          <Col>
            <Row className="px-3">
              <Col sm="12" xl="6" className="competence-dash mt-2">
                <h6>Availability</h6>
                <div className="form-check form-check-inline">
                  <Input
                    type="checkbox"
                    name="availability"
                    id="availability1"
                    className="form-check-input"
                    value="fullTime"
                    checked={flData.availability && flData.availability.includes('fullTime')}
                    onChange={setAvailability}
                  />
                  <label className="form-check-label" htmlFor="availability1">Full time</label>
                </div>
                <div className="form-check form-check-inline">
                  <Input
                    type="checkbox"
                    name="availability"
                    id="availability2"
                    className="form-check-input"
                    value="partTime"
                    checked={flData.availability && flData.availability.includes('partTime')}
                    onChange={setAvailability}
                  />
                  <label className="form-check-label" htmlFor="availability2">Part time</label>
                </div>
              </Col>

              <Col sm="12" xl="6" className="competence-dash mt-2">
                <h6>Workplace</h6>
                <div className="form-check form-check-inline">
                  <Input
                    type="checkbox"
                    name="workplace"
                    id="workplace1"
                    className="form-check-input"
                    value="atRemote"
                    checked={flData.workplace && flData.workplace.includes('atRemote')}
                    onChange={setWorkplace}
                  />
                  <label className="form-check-label" htmlFor="workplace1">At Remote</label>
                </div>
                <div className="form-check form-check-inline">
                  <Input
                    type="checkbox"
                    name="workplace"
                    id="workplace2"
                    className="form-check-input"
                    value="atCustomer"
                    checked={flData.workplace && flData.workplace.includes('atCustomer')}
                    onChange={setWorkplace}
                  />
                  <label className="form-check-label" htmlFor="workplace2">At the customer</label>
                </div>
              </Col>

              <Col sm="12" className="competence-dash mt-4">
                <h6>Level of experience</h6>
                <div className="round-inputs-box mb-5">
                  <Range
                    className="mt-3"
                    min={0} max={10} step={1} defaultValue={[0]}
                    value={[flData.yearsOfExperience || 0]}
                    tipFormatter={(value) => `${value} ${value ? '+ years' : ' year'}`}
                    marks={experienceMarks}
                    onChange={setYearsOfExperience}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <hr />

        <Row className="m-0 p-0">
          <Col xl="6">
            <h5>LANGUAGES</h5>
            {flData.languages && flData.languages.length ? (
              (flData.languages.map((l, i) => {
                return (
                  <div className="dash-lanuguage" key={i}>
                    <Button
                      className="btn btn-secondary btn-delete p-0 m-0 border-0 bg-transparent"
                      onClick={() => deleteLanguage(i)}
                    >
                      &#10006;
                    </Button>
                    <div className="form-group">
                      <Select
                        name="language-lang"
                        className="react-select react-select-primary"
                        classNamePrefix="profile-react-select"
                        value={{ value: l.lang, label: l.lang }}
                        onChange={(e) => updateLanguage(i, 'lang', e.value)}
                        options={languageList.filter(lang => !flData.languages.map(l => l.lang).includes(lang.value))}
                      />
                    </div>
                    <div className="dash">-</div>
                    <div className="form-group">
                      <Select
                        name="language-proficiency"
                        className="react-select react-select-primary"
                        classNamePrefix="profile-react-select"
                        value={{ value: l.proficiency, label: l.proficiency }}
                        onChange={(e) => updateLanguage(i, 'proficiency', e.value)}
                        options={langProficiencyList}
                      />
                    </div>
                  </div>
                )
              }))
            ) : (
              <></>
            )}

            <div className="language-add-btn">
              <Button
                color="secondary"
                className="bg-transparent p-0 m-0 border-0 shadow-none"
                onClick={addNewLanguage}
              >
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="72" height="72" viewBox="0 0 72 72">
                  <defs>
                    <filter id="Rectangle_317" x="0" y="0" width="72" height="72" filterUnits="userSpaceOnUse">
                      <feOffset dy="1" input="SourceAlpha" />
                      <feGaussianBlur stdDeviation="5" result="blur" />
                      <feFlood floodOpacity="0.102" />
                      <feComposite operator="in" in2="blur" />
                      <feComposite in="SourceGraphic" />
                    </filter>
                  </defs>
                  <g id="ADD" transform="translate(-484 -1153)">
                    <g transform="matrix(1, 0, 0, 1, 484, 1153)" filter="url(#Rectangle_317)">
                      <rect id="Rectangle_317-2" data-name="Rectangle 317" width="42" height="42" rx="21" transform="translate(15 14)" fill="#fff" />
                    </g>
                    <line id="Line_59" data-name="Line 59" y2="16" transform="translate(520.5 1180.5)" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="1" />
                    <line id="Line_60" data-name="Line 60" y2="16" transform="translate(528.5 1188.5) rotate(90)" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="1" />
                  </g>
                </svg>
              </Button>
            </div>
          </Col>

          <Col xl="6">
            <h5>Networks</h5>
            <div className="social-media-link-edit">
              <div className="facebook">
                <img
                  alt=""
                  src={require(`../../assets/images/Facebook.svg`).default}
                />
                <Input
                  type="url"
                  name="facebookURL"
                  id="facebookURL"
                  className="form-control"
                  placeholder="URL"
                  value={flData.networks && flData.networks.find(n => n.website === 'facebook') ? flData.networks.find(n => n.website === 'facebook')['url'] : ""}
                  onChange={(e) => setNetwork(e, 'facebook')}
                />
              </div>
              <div className="facebook">
                <img
                  alt=""
                  src={require(`../../assets/images/Instagram.svg`).default}
                />
                <Input
                  type="url"
                  id="instagramURL"
                  name="instagramURL"
                  className="form-control"
                  placeholder="URL"
                  value={flData.networks && flData.networks.find(n => n.website === 'instagram') ? flData.networks.find(n => n.website === 'instagram')['url'] : ""}
                  onChange={(e) => setNetwork(e, 'instagram')}
                />
              </div>
              <div className="facebook">
                <img
                  alt=""
                  src={require(`../../assets/images/Linkedin.svg`).default}
                />
                <Input
                  type="url"
                  name="linkedinURL"
                  id="linkedinURL"
                  className="form-control"
                  placeholder="URL"
                  value={flData.networks && flData.networks.find(n => n.website === 'linkedin') ? flData.networks.find(n => n.website === 'linkedin')['url'] : ""}
                  onChange={(e) => setNetwork(e, 'linkedin')}
                />
              </div>
              <div className="facebook">
                <img
                  alt=""
                  src={require(`../../assets/images/Behance.svg`).default}
                />
                <Input
                  type="url"
                  name="behanceURL"
                  id="behanceURL"
                  className="form-control"
                  placeholder="URL"
                  value={flData.networks && flData.networks.find(n => n.website === 'behance') ? flData.networks.find(n => n.website === 'behance')['url'] : ""}
                  onChange={(e) => setNetwork(e, 'behance')}
                />
              </div>
            </div>
          </Col>
        </Row>
        <hr />

        <Row className="m-0 p-0">
          <Col sm="12">
            <h5>Skills</h5>
          </Col>

          <Col sm="12" className="competence-dash">
            <div className="round-inputs-box mb-3">
              <TagsInput
                inputProps={{ placeholder: "Add a skill" }}
                tagProps={{ className: "custom-tagsinput-tag react-tagsinput-tag bg-secondary" }}
                value={flData.skills || []}
                onChange={handleSkills}
              />
            </div>
          </Col>
        </Row>
        <hr />

        <Row className="m-0 p-0">
          <Col sm="12">
            <h5 className="mb-0">Experiences</h5>
          </Col>

          <Col sm="12">
            <div className={flData.experiences && flData.experiences.length ? "experience-inner-box1" : ""}>
              {flData.experiences && flData.experiences.length ? (
                (flData.experiences.map((exp, i) => {
                  return (
                    <div className="experience-inner-box mt-3" key={i}>
                      <Button
                        className="btn btn-secondary btn-delete p-0 m-0 border-0 bg-transparent"
                        onClick={() => deleteExperience(i)}
                      >
                        &#10006;
                      </Button>
                      <Row>
                        <Col xl="4">
                          <Input
                            type="text"
                            name="experience-name"
                            id="experience-name"
                            className="form-control gry-input"
                            value={exp.name}
                            placeholder="Company Name"
                            maxLength="200"
                            onChange={(e) => updateExperience(i, 'name', e.target.value)}
                          />
                        </Col>
                        <Col xl="8">
                          <div className="exp-date-part">
                            <div className="d-flex du">
                              <span>Start</span>
                              <Input
                                type="date"
                                name="experience-startDate"
                                id="experience-startDate"
                                className="form-control dash-date-picker"
                                value={moment(exp.startDate).format("YYYY-MM-DD")}
                                onChange={(e) => updateExperience(i, 'startDate', e.target.value)}
                              />
                            </div>
                            <div className="d-flex au">
                              <span>End</span>
                              <Input
                                type="date"
                                name="experience-endDate"
                                id="experience-endDate"
                                disabled={exp.isPresent}
                                className="form-control dash-date-picker"
                                value={exp.endDate ? moment(exp.startDate).format("YYYY-MM-DD") : ''}
                                onChange={(e) => updateExperience(i, 'endDate', e.target.value)}
                              />
                            </div>
                            <div className="mt-0">
                              {/* add active class to change btn style */}
                              <Button
                                name="experience-isPresent"
                                color="secondary"
                                className={`en-course-btn shadow-none pb-2 ${exp.isPresent ? "active" : ""}`}
                                onClick={(e) => updateExperience(i, 'isPresent', !exp.isPresent)}
                              >Present
                            </Button>
                            </div>
                          </div>
                        </Col>

                        <Col sm="12">
                          <div className="post mt-4">
                            <Input
                              type="text"
                              name="experience-post"
                              id="experience-post"
                              className="form-control post-input"
                              value={exp.post}
                              placeholder="Post"
                              maxLength="100"
                              onChange={(e) => updateExperience(i, 'post', e.target.value)}
                            />
                          </div>
                          <div className="post mt-4">
                            <textarea
                              rows="4"
                              name="experience-description"
                              id="experience-description"
                              className="form-control text-area-exp"
                              value={exp.description}
                              placeholder="Description (max: 1000 characters)"
                              maxLength="1000"
                              onChange={(e) => updateExperience(i, 'description', e.target.value)}
                            ></textarea>
                          </div>
                          <div className="skill-add-section mt-4">
                            <h6>Software / Tools</h6>
                            <div className="round-inputs-box">
                              <TagsInput
                                name="experience-technologies"
                                id="experience-technologies"
                                inputProps={{ placeholder: "Add a Software / Tool" }}
                                tagProps={{ className: "custom-tagsinput-tag react-tagsinput-tag bg-secondary" }}
                                value={exp.technologies}
                                onChange={(value) => updateExperience(i, 'technologies', Array.from(new Set(value)))}
                                updateExperience
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  )
                }))
              ) : (
                <></>
              )}
            </div>

            <div className="">
              <Button
                color="secondary"
                className="exp-add-btn mt-3 bg-transparent m-0 p-0 border-0 shadow-none"
                onClick={addNewExperience}
              >
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="72" height="72" viewBox="0 0 72 72">
                  <defs>
                    <filter id="Rectangle_317" x="0" y="0" width="72" height="72" filterUnits="userSpaceOnUse">
                      <feOffset dy="1" input="SourceAlpha"></feOffset>
                      <feGaussianBlur stdDeviation="5" result="blur"></feGaussianBlur>
                      <feFlood floodOpacity="0.102"></feFlood>
                      <feComposite operator="in" in2="blur"></feComposite>
                      <feComposite in="SourceGraphic"></feComposite>
                    </filter>
                  </defs>
                  <g id="ADD" transform="translate(-484 -1153)">
                    <g transform="matrix(1, 0, 0, 1, 484, 1153)" filter="url(#Rectangle_317)">
                      <rect id="Rectangle_317-2" data-name="Rectangle 317" width="42" height="42" rx="21" transform="translate(15 14)" fill="#fff"></rect>
                    </g>
                    <line id="Line_59" data-name="Line 59" y2="16" transform="translate(520.5 1180.5)" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="1"></line>
                    <line id="Line_60" data-name="Line 60" y2="16" transform="translate(528.5 1188.5) rotate(90)" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="1"></line>
                  </g>
                </svg>
              </Button>
            </div>
            <hr />

            <div className="portfolio-section-project mt-5">
              <h5 className="mb-4">Portfolio</h5>
              <Row>
                {flData.portfolios && flData.portfolios.map((pf, i) => {
                  return (
                    <Col key={i} md="6" lg="3"
                      className="d-flex justify-content-center pointer"
                      onClick={() => showPortfolioData(i)}
                    >
                      <div className="project-box">
                        <Button
                          className="btn btn-secondary btn-delete p-0 m-0 border-0 bg-transparent"
                          onClick={(e) => deletePortfolio(e, i)}
                        >
                          &#10006;
                        </Button>
                        <div className="inner-box-project">
                          <div className="project-image">
                            {pf.image && pf.image.data ? (
                              <img
                                alt=""
                                className="img-fluid"
                                src={`data:${pf.image.contentType};base64,${(pf.image.data)}`}
                              />
                            ) : (
                              <img
                                alt="" className="img-fluid"
                                src={require(`../../assets/images/project-img.jpg`).default}
                              />
                            )}
                          </div>
                          <div className="project-title">
                            <h6>{pf.name}</h6>
                          </div>
                        </div>
                      </div>
                    </Col>
                  );
                })}

                <Col md="6" lg="3" className="d-flex justify-content-center">
                  <div className="project-box add-project w-100">
                    <Button
                      color="secondary"
                      className="inner-box-project add-project-btn bg-transparent shadow-none m-0 p-0 border-0"
                      onClick={addNewPortfolio}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="112" height="140" viewBox="0 0 112 140">
                        <g id="Group_319" data-name="Group 319" transform="translate(-547 -3168)">
                          <g id="Rectangle_330" data-name="Rectangle 330" transform="translate(547 3168)" fill="#fff" stroke="#000" strokeWidth="1">
                            <rect width="112" height="140" rx="10" stroke="none" />
                            <rect x="0.5" y="0.5" width="111" height="139" rx="9.5" fill="none" />
                          </g>
                          <g id="Group_232" data-name="Group 232" transform="translate(6.063 11.563)">
                            <line id="Line_64" data-name="Line 64" x2="35.873" transform="translate(579.5 3226.937)" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="2" />
                            <line id="Line_65" data-name="Line 65" x2="35.873" transform="translate(597.437 3209) rotate(90)" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="2" />
                          </g>
                        </g>
                      </svg>
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>

        <Row className="m-0 p-0 justify-content-center">
          <Col sm="12" className="text-center">
            <Button
              color="secondary"
              className="mt-3 mx-1 py-2 px-5 contact-us-btn more-btn bg-secondary"
              disabled={JSON.stringify(defaultData) === JSON.stringify(flData)}
              onClick={cancelChanges}>
              Cancel
            </Button>
            <Button
              color="primary"
              className="mt-3 mx-1 py-2 px-5 contact-us-btn more-btn"
              disabled={JSON.stringify(defaultData) === JSON.stringify(flData)}
              onClick={updateProfile}>
              Save
            </Button>
          </Col>
        </Row>

        {/* popup content */}
        <Modal
          isOpen={showPFModal}
          toggle={closePFModal}
          onClosed={closePFModal}
          id="portfolioModal"
          backdrop="static"
        >
          <ModalHeader cssModule={{ 'modal-title': 'w-100' }}>
            <Input
              type="text"
              name="projectName"
              id="projectName"
              className="form-control gry-input"
              value={pfFormData.name}
              placeholder="Project Name"
              maxLength="100"
              onChange={(e) => updatePFForm('name', e.target.value)}
            />
            <Button
              className="close"
              onClick={closePFModal}
            >
              <span aria-hidden="true">&times;</span>
            </Button>
          </ModalHeader>
          <ModalBody>
            {pfFormData.image && pfFormData.image.data ? (
              <img
                alt=""
                className="img-fluid pf-img"
                src={`data:${pfFormData.image.contentType};base64,${(pfFormData.image.data)}`}
              />
            ) : (
              <></>
            )}
            <div className="drag-and-drop-img" onClick={triggerPFImageFile}>
              <h5 className="mb-1">IMAGE</h5>
              <p class="mb-2">CLICK HERE</p>
              <p style={{ 'fontSize': '11px', 'fontWeight': '500' }}>
                [IF IMAGE ISN'T PROVIDED THEN IT TAKES A DEFAULT IMAGE]
              </p>
            </div>
            <Input
              type="file"
              id="imgupload"
              className="d-none"
              innerRef={pfImageRef}
              accept="image/x-png,image/webp,image/jpeg"
              onChange={(e) => uploadPFImage(e)}
            />
            <div className="project-description-drag-drop">
              <div className="post mt-4">
                <textarea
                  rows="4"
                  name="projectDescription"
                  id="projectDescription"
                  className="form-control text-area-exp"
                  value={pfFormData.description}
                  placeholder="Description (max: 1000 characters)"
                  maxLength="1000"
                  onChange={(e) => updatePFForm('description', e.target.value)}
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="text-center mt-2 w-100">
              <Button
                color="secondary"
                className="w-25 mx-1 shadow-none"
                onClick={closePFModal}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                className="more-btn w-25 mx-1 shadow-none"
                onClick={savePortfolio}
              >
                Save
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  )
}

export default Freelancer;