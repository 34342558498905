import React, { useContext, useEffect, useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { FirebaseContext } from "../contexts";
import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardBody,
} from "reactstrap";
import {
  blogService,
  toastAlert,
  getFormattedDate,
  arrayBufferToBase64,
} from "../Services";
// Core Components
import Header from "../components/Layout/Header";

const Blog = () => {
  const { setLoading } = useContext(FirebaseContext);
  const [blogCategories, setBlogCategories] = useState([]);
  const [blogs, setBlogs] = useState(null);
  const [firstBlog, setFirstBlog] = useState(null);
  const [remainingBlogs, setRemainingBlogs] = useState(null);
  const [lastBlog, setLastBlog] = useState(null);
  const [activeTab, setActiveTab] = useState("All");

  const toggleTabContent = (tab) => {
    setActiveTab(tab);
    let catBlogArr = [...blogs];
    if (tab !== "All") {
      catBlogArr = catBlogArr.filter((b) => b.category === tab);
    }
    let { firstBlog, lastBlog, remainingBlogs } = getBlogsData(catBlogArr);
    setFirstBlog(firstBlog);
    setLastBlog(lastBlog);
    setRemainingBlogs(remainingBlogs);
  };

  const getBlogsData = (blogArr) => {
    let blogs = blogArr.sort((b1, b2) => {
      return (
        new Date(b2.updatedAt).getTime() - new Date(b1.updatedAt).getTime()
      );
    });
    let noOfBlogs = blogs.length;

    let firstBlog = null;
    if (noOfBlogs) {
      firstBlog = blogs[0];
    }

    let lastBlog = null;
    if (noOfBlogs > 1) {
      lastBlog = blogs[noOfBlogs - 1];
    }

    let remainingBlogs = [];
    if (noOfBlogs > 1) {
      remainingBlogs = blogs.slice(1, noOfBlogs - 1);
    }

    return { blogs, firstBlog, lastBlog, remainingBlogs };
  };

  const getAllBlogs = useCallback(async () => {
    try {
      let allBlogs = await blogService.getAllBlogs();
      let blogCategories = await blogService.getBlogCategories();
      let { blogs, firstBlog, lastBlog, remainingBlogs } = getBlogsData(
        allBlogs
      );
      setBlogCategories(blogCategories);
      setBlogs(blogs);
      setFirstBlog(firstBlog);
      setLastBlog(lastBlog);
      setRemainingBlogs(remainingBlogs);
      setLoading(false);
    } catch (error) {
      setBlogs([]);
      setLoading(false);
      toastAlert("error", error.message || "Error while fetching blogs");
    }
  }, [setLoading]);

  useEffect(() => {
    document.body.classList.add("white-bg");
    getAllBlogs();
  }, [getAllBlogs]);

  useEffect(() => {
    return () => {
      document.body.classList.remove("white-bg");
      setLoading(true);
    };
  }, [setLoading]);

  return (
    <>
      <div className="header-nav-bg">
        <Header />
        <section>
          <Container>
            <Row className="mt-4 blog-title blog-section">
              <Col md="12" className="text-center">
                <h1 className="mb-3 blog-main-title">Blog</h1>
                <h5 className="mb-3 blog-sub-title">
                  All the latest news on sneakers, bots, proxies and servers
                </h5>
                <Nav
                  pills
                  className="mb-3 justify-content-center"
                  id="pills-tab"
                  role="tablist"
                >
                  <NavItem>
                    <NavLink
                      className={`${activeTab === "All" ? "active" : ""}`}
                      onClick={() => {
                        toggleTabContent("All");
                      }}
                    >
                      All
                    </NavLink>
                  </NavItem>
                  {blogCategories.map((cat, i) => {
                    return (
                      <NavItem key={i}>
                        <NavLink
                          className={`${activeTab === cat["key"] ? "active" : ""
                            }`}
                          onClick={() => {
                            toggleTabContent(cat["key"]);
                          }}
                        >
                          {cat["name"]}
                        </NavLink>
                      </NavItem>
                    );
                  })}
                </Nav>
              </Col>

              <div className="blog-details-section mt-4">
                {firstBlog ? (
                  <Link to={`/blog/${firstBlog.url}`}>
                    <Row className="m-0 p-0">
                      <Col md="7">
                        <img
                          alt=""
                          className="img-fluid"
                          src={`data:${firstBlog.image.contentType
                            };base64,${arrayBufferToBase64(
                              firstBlog.image.data.data
                            )}`}
                        />
                      </Col>
                      <Col md="5">
                        <div className="grey-badge mt-2">
                          <span className="grey-bg">
                            {firstBlog.category.toUpperCase()}
                          </span>
                          <span className="date">
                            {getFormattedDate(new Date(firstBlog.updatedAt))}
                          </span>
                        </div>
                        <h5 className="card-title news-title text-left">
                          {firstBlog.title}
                        </h5>
                        <div className="card-text news-text">
                          {firstBlog.description}
                        </div>
                      </Col>
                    </Row>
                  </Link>
                ) : (
                  <></>
                )}

                <Row className="mt-5 m-0 p-0">
                  {remainingBlogs && remainingBlogs.length ? (
                    remainingBlogs.map((blog, i) => {
                      return (
                        <Col
                          md={
                            remainingBlogs.length === 1
                              ? "12"
                              : remainingBlogs.length === 2
                                ? "6"
                                : "4"
                          }
                          key={i}
                        >
                          <Link to={`/blog/${blog.url}`}>
                            <Card className="mb-5">
                              <img
                                alt=""
                                className="img-fluid"
                                src={`data:${blog.image.contentType
                                  };base64,${arrayBufferToBase64(
                                    blog.image.data.data
                                  )}`}
                              />
                              <CardBody className="p-0 mt-2">
                                <div className="grey-badge">
                                  <span className="grey-bg">
                                    {blog.category.toUpperCase()}
                                  </span>
                                  <span className="date">
                                    {getFormattedDate(new Date(blog.updatedAt))}
                                  </span>
                                </div>
                                <h5 className="card-title news-title text-left">
                                  {blog.title}
                                </h5>
                                <p className="card-text news-text">
                                  {blog.description}
                                </p>
                              </CardBody>
                            </Card>
                          </Link>
                        </Col>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </Row>

                {lastBlog ? (
                  <Link to={`/blog/${lastBlog.url}`}>
                    <Row className="m-0 p-0">
                      <Col md="7">
                        <img
                          alt=""
                          className="img-fluid"
                          src={`data:${lastBlog.image.contentType
                            };base64,${arrayBufferToBase64(
                              lastBlog.image.data.data
                            )}`}
                        />
                      </Col>
                      <Col md="5">
                        <div className="grey-badge mt-2">
                          <span className="grey-bg">
                            {lastBlog.category.toUpperCase()}
                          </span>
                          <span className="date">
                            {getFormattedDate(new Date(lastBlog.updatedAt))}
                          </span>
                        </div>
                        <h5 className="card-title news-title text-left">
                          {lastBlog.title}
                        </h5>
                        <div className="card-text news-text">
                          {firstBlog.description}
                        </div>
                      </Col>
                    </Row>
                  </Link>
                ) : (
                  <></>
                )}
              </div>
            </Row>
          </Container>
        </section>
      </div>
    </>
  );
};

export default Blog;