/*
  Function that returns formated date
 */
function getFormattedDate(date = new Date(), seperator = '.') {
  let dd = date.getDate();
  let mm = date.getMonth() + 1;
  let yyyy = date.getFullYear();

  dd = dd < 10 ? `0${dd}` : dd;
  mm = mm < 10 ? `0${mm}` : mm;
  return `${dd}${seperator}${mm}${seperator}${yyyy}`;
}

function getExperienceDate(date = new Date()) {
  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();
  return `${month} ${year}`
}

/*
  Function that convert buffer data into base64
 */
function arrayBufferToBase64(buffer) {
  let binary = '';
  let bytes = [].slice.call(new Uint8Array(buffer));
  bytes.forEach((b) => binary += String.fromCharCode(b));
  return window.btoa(binary);
};

/*
  Function that splits array into group of items array
 */
function chunkArrayInGroups(arr, size) {
  const chunkArray = [];
  for (let i = 0; i < arr.length; i += size) {
    chunkArray.push(arr.slice(i, i + size));
  }
  return chunkArray;
}

export {
  getFormattedDate,
  getExperienceDate,
  arrayBufferToBase64,
  chunkArrayInGroups
};