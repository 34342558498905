const axios = require('axios');
const API_SERVER_URL = process.env.REACT_APP_API_URL;


class ChatService {
  async initChat(data) {
    try {
      const config = {
        method: 'POST',
        url: `${API_SERVER_URL}/chats/initChat`,
        headers: {
          contentType: 'application/json'
        },
        data: data
      };
      let response = await axios(config);
      if (response.status === 200 && response.data.status === 'success') {
        return true;
      } else {
        throw new Error(response.data.message || 'Error while initializing chat with given user');
      }
    } catch (error) {
      throw new Error(error.message || 'Error while initializing chat with given user');
    }
  }

  async getAllChats(data) {
    try {
      const config = {
        method: 'POST',
        url: `${API_SERVER_URL}/chats/getAll`,
        headers: {
          contentType: 'application/json'
        },
        data: data
      };
      let response = await axios(config);
      if (response.status === 200 && response.data.status === 'success') {
        return response.data.data;
      } else {
        throw new Error(response.data.message || 'Error while fetching messages');
      }
    } catch (error) {
      throw new Error(error.message || 'Error while fetching messages');
    }
  }
}

const chatService = new ChatService();
export {
  chatService
};