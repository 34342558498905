import React, { useContext } from "react";
import { FirebaseContext } from "../../contexts";
import { loadStripe } from "@stripe/stripe-js";
import { Button } from "reactstrap";
import { toastAlert } from "../../Services";

var Stripe;
(async () => {
  Stripe = await loadStripe(process.env.REACT_APP_STRIPE_KEY);
})();
const axios = require("axios");
const API_SERVER_URL = process.env.REACT_APP_API_URL;

const Subscribe = () => {
  const { firebase, setLoading } = useContext(FirebaseContext);

  const doSubscribe = async (event) => {
    let $current = event.target;
    try {
      setLoading(true);
      $current.disabled = true;
      let idToken = await firebase.auth.currentUser.getIdToken(true);
      const config = {
        method: "POST",
        url: `${API_SERVER_URL}/stripe/subscribe`,
        data: { idToken },
        headers: {
          contentType: 'application/json'
        }
      };
      let response = await axios(config);
      if (response.status === 200) {
        if (response.data.id) {
          Stripe.redirectToCheckout({
            sessionId: response.data.id,
          }).then((result) => {
            if ("error" in result) {
              throw new Error(result["error"]["message"]);
            }
            setLoading(false);
          });
        } else {
          throw new Error(
            "Something went wrong with your payment, Please try again later !"
          );
        }
      } else {
        throw new Error(
          response.message || "Error occured when creating a subscription"
        );
      }
    } catch (error) {
      $current.disabled = false;
      setLoading(false);
      toastAlert(
        "error",
        error.message || "Error occured when creating a subscription"
      );
    }
  };

  return (
    <div className="d-flex page-content-dashboard custom-height">
      <div className="dashboard-subscribe-content">
        <h5 className="text-center">
          Subscribe here to get access to a freelance profile?
        </h5>
        <h5 className="text-center">Only 10 €/month</h5>
        <div className="read-all-btn text-center mt-3">
          <Button
            className="contact-us-btn more-btn py-2 px-4"
            onClick={doSubscribe}
          >
            Subscribe
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Subscribe;
