import React, { useContext, useEffect, useCallback, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { FirebaseContext } from "../contexts";
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import {
  freelancerService,
  chatService,
  getExperienceDate,
  toastAlert
} from "../Services";
// Core Components
import Header from "../components/Layout/Header";


const HireProfileView = () => {
  const { uid } = useParams();
  const history = useHistory();
  const { user, firebase, setLoading } = useContext(FirebaseContext);
  const [freelancerData, setFreelancerData] = useState({
    uid: "",
    image: {},
    active: false,
    firstName: "",
    lastName: "",
    profile: "",
    location: "",
    about: "",
    price: 0,
    currency: "usd",
    yearsOfExperience: "",
    languages: [],
    networks: [],
    skills: [],
    experiences: [],
    portfolios: [],
  });
  const [facebookURL, setFacebookURL] = useState("");
  const [instagramURL, setInstagramURL] = useState("");
  const [linkedinURL, setLinkedinURL] = useState("");
  const [behanceURL, setBehanceURL] = useState("");

  const defaultPFData = { name: "", image: { data: '', contentType: '' }, description: "", };
  const [pfFormData, setPFFormData] = useState(defaultPFData);
  const [showPFModal, setShowPFModal] = useState(false);
  const closePFModal = () => {
    setPFFormData(defaultPFData);
    setShowPFModal(false);
  };

  const showPFData = (index) => {
    let pfData = [...freelancerData.portfolios][index];
    setPFFormData(pfData);
    setShowPFModal(true);
  }

  const initChat = async () => {
    try {
      setLoading(true);
      const data = {};
      let idToken = await firebase.auth.currentUser.getIdToken(true);
      data['uid'] = uid;
      data['idToken'] = idToken;
      chatService.initChat(data).then(status => {
        history.push('/message');
      });
    } catch (error) {
      setLoading(false);
      toastAlert('error', error.message || 'Error while initializing chat with given user');
    }
  }

  const getFreelancerData = useCallback(async () => {
    try {
      let data = await freelancerService.getFreelancerByUID({ uid });
      setFreelancerData({ ...data });
      const facebookURL = data.networks.find(n => n.website === 'facebook') ? data.networks.find((n) => n.website === 'facebook')['url'] : '';
      const instagramURL = data.networks.find(n => n.website === 'instagram') ? data.networks.find((n) => n.website === 'instagram')['url'] : '';
      const linkedinURL = data.networks.find(n => n.website === 'linkedin') ? data.networks.find((n) => n.website === 'linkedin')['url'] : '';
      const behanceURL = data.networks.find(n => n.website === 'behance') ? data.networks.find((n) => n.website === 'behance')['url'] : '';
      setFacebookURL(facebookURL);
      setInstagramURL(instagramURL);
      setLinkedinURL(linkedinURL);
      setBehanceURL(behanceURL);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toastAlert("error", error.message || "Error while fetching freelancer profile");
    }
  }, [uid, setLoading]);

  useEffect(() => {
    document.body.classList.add("white-bg");
    document.getElementsByClassName("footer-bg") &&
      document.getElementsByClassName("footer-bg")[0].classList.add("mt150");
    getFreelancerData();
  }, [getFreelancerData]);

  useEffect(() => {
    return () => {
      setLoading(true);
      document.body.classList.remove("white-bg");
      document.getElementsByClassName("footer-bg") &&
        document.getElementsByClassName("footer-bg")[0] &&
        document.getElementsByClassName("footer-bg")[0].classList.remove("mt150");
    };
  }, [setLoading]);

  return (
    <>
      <div className="header-nav-bg">
        <Header />

        <Container>
          <Row className="mt-1 mt-sm-5 profle-main">
            <Col lg="5">
              <Row className="profile-person text-center text-lg-left">
                <Col lg="5">
                  <div className="profile-person-img">
                    {freelancerData.image && freelancerData.image.data ? (
                      <img
                        alt="" className="img-fluid rounded-circle"
                        src={`data:${freelancerData.image.contentType};base64,${freelancerData.image.data}`}
                      />
                    ) : (
                      <img
                        alt="" className="img-fluid rounded-circle"
                        src={require(`../assets/images/profile-pic-upload.png`).default}
                      />
                    )}
                  </div>
                </Col>
                <Col lg="7">
                  <div className="profile-person-details">
                    <h5 className="profile-name">
                      {freelancerData.firstName}&nbsp;{freelancerData.lastName}
                    </h5>
                    <div className="profile-designation my-2">
                      {freelancerData.profile}
                    </div>
                    <div className="profile-location">
                      <span>
                        <i className="fa fa-map-marker-alt"></i>
                      </span>{" "}
                      {freelancerData.location}
                    </div>
                    {/* <div className="profile-rating my-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="145.396"
                        height="21.485"
                        viewBox="0 0 145.396 21.485"
                      >
                        <g id="Stars" transform="translate(-169.913 -2429.388)">
                          <path
                            id="star" fill="#000"
                            d="M13.087,18.4l6.234,3.762-1.654-7.091L23.174,10.3l-7.253-.625L13.087,3,10.253,9.678,3,10.3l5.5,4.771L6.853,22.166Z"
                            transform="translate(166.913 2427.667)"
                          />
                          <path
                            id="star-2" fill="#000"
                            d="M13.087,18.4l6.234,3.762-1.654-7.091L23.174,10.3l-7.253-.625L13.087,3,10.253,9.678,3,10.3l5.5,4.771L6.853,22.166Z"
                            transform="translate(197.913 2427.667)"
                          />
                          <path
                            id="star-3" fill="#000"
                            d="M13.087,18.4l6.234,3.762-1.654-7.091L23.174,10.3l-7.253-.625L13.087,3,10.253,9.678,3,10.3l5.5,4.771L6.853,22.166Z"
                            transform="translate(228.913 2427.667)"
                          />
                          <path
                            id="star-4" fill="#000"
                            d="M13.087,18.4l6.234,3.762-1.654-7.091L23.174,10.3l-7.253-.625L13.087,3,10.253,9.678,3,10.3l5.5,4.771L6.853,22.166Z"
                            transform="translate(259.913 2427.667)"
                          />
                          <path
                            id="star-5" fill="none"
                            stroke="#000" strokeWidth="1"
                            d="M13.087,18.4l6.234,3.762-1.654-7.091L23.174,10.3l-7.253-.625L13.087,3,10.253,9.678,3,10.3l5.5,4.771L6.853,22.166Z"
                            transform="translate(290.913 2427.667)"
                          />
                        </g>
                      </svg>{" "}
                      (10 reviews)
                    </div> */}
                    <div className="profile-rate my-2">
                      {freelancerData.price}
                      {freelancerData.currency === "usd" ? "$" : "€"}&nbsp;/&nbsp;hour
                    </div>
                    {user && user.uid !== uid && (
                      <div className="profile-proposer-btn mt-4">
                        <Button
                          color="primary"
                          className="contact-us-btn more-btn shadow-none py-2 px-4"
                          onClick={initChat}
                        >
                          Send a message
                        </Button>
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg="7">
              <div className="a-propos mt-5 mt-lg-0">
                <h5 className="profile-name">About</h5>
                <div className="propos-text">
                  <textarea
                    rows="7"
                    className="form-control bg-transparent p-0 border-0"
                    placeholder="About freelancer"
                    value={freelancerData.about}
                    readOnly={true}
                  />
                </div>
              </div>
            </Col>
          </Row>

          <Row className="mt-5">
            <Col lg="3">
              <div className="language-section">
                <h5>LANGUAGES</h5>
                {freelancerData.languages && freelancerData.languages.length ? (
                  freelancerData.languages.map((l, i) => {
                    return (
                      <div key={i}>
                        <strong>{l.lang}&nbsp;</strong>-&nbsp;{l.proficiency}
                      </div>
                    );
                  })
                ) : (
                  <></>
                )}
              </div>

              <div className="profile-social-media my-4">
                <h5>Networks</h5>
                <div className="social-media-icon-profile">
                  <a
                    href={facebookURL} target="_blank" rel="noreferrer"
                    className={facebookURL ? "" : "not-allowed"}
                  >
                    <img
                      alt="" className="img-fluid"
                      src={require(`../assets/images/Facebook.svg`).default}
                    />
                  </a>
                  <a
                    href={instagramURL} target="_blank" rel="noreferrer"
                    className={instagramURL ? "" : "not-allowed"}
                  >
                    <img
                      alt="" className="img-fluid"
                      src={require(`../assets/images/Instagram.svg`).default}
                    />
                  </a>
                  <a
                    href={linkedinURL} target="_blank" rel="noreferrer"
                    className={linkedinURL ? "" : "not-allowed"}
                  >
                    <img
                      alt="" className="img-fluid"
                      src={require(`../assets/images/Linkedin.svg`).default}
                    />
                  </a>
                  <a
                    href={behanceURL} target="_blank" rel="noreferrer"
                    className={behanceURL ? "" : "not-allowed"}
                  >
                    <img
                      alt="" className="img-fluid behance-logo"
                      src={require(`../assets/images/Behance.svg`).default}
                    />
                  </a>
                </div>
              </div>
            </Col>
            <Col lg="9" className="skill-sec">
              <h5>SKILLS</h5>
              <Row className="m-0 p-0">
                <Col sm="12" className="mb-md-0 mb-3">
                  <ul className="list-unstyled">
                    {freelancerData.skills && freelancerData.skills.length ? (
                      freelancerData.skills.map((s, i) => {
                        return <li key={i}>{s}</li>;
                      })
                    ) : (
                      <></>
                    )}
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>

          {freelancerData.experiences && freelancerData.experiences.length ? (
            <div className="experience-section mt-5">
              <h5 className="mb-4 ex-h5">experiences</h5>
              {freelancerData.experiences.map((exp, i) => {
                return (
                  <div className="experience-box mb-3" key={i}>
                    <div className="exp-date text-lead">
                      <em>
                        {getExperienceDate(new Date(exp.startDate))}
                        &nbsp;-&nbsp;
                        {exp.endDate ? getExperienceDate(new Date(exp.endDate)) : "Present"}
                      </em>
                    </div>
                    <h5>{exp.name}</h5>
                    <div className="exp-designation">{exp.post}</div>
                    <p className="mt-2">{exp.description}</p>
                    <div className="exp-badges">
                      <h6>SOFTWARE / TOOLS</h6>
                      <div className="exp-blue-badge">
                        {exp.technologies && exp.technologies.length ? (
                          exp.technologies.map((tech, j) => {
                            return (
                              <Button className="btn btn-outline-primary bg-transparent" key={j}>
                                {tech}
                              </Button>
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <></>
          )}

          {freelancerData.portfolios && freelancerData.portfolios.length ? (
            <div>
              <h5 className="mb-4">Portfolio</h5>
              <Row>
                {freelancerData.portfolios.map((pf, i) => {
                  return (
                    <Col key={i} md="6" lg="3"
                      className="d-flex justify-content-center pointer"
                      onClick={() => showPFData(i)}
                    >
                      <div className="project-box">
                        <div className="inner-box-project">
                          <div className="project-image">
                            {pf.image && pf.image.data ? (
                              <img
                                alt=""
                                className="img-fluid"
                                // src={require(`../assets/images/project-img.jpg`).default}
                                src={`data:${pf.image.contentType};base64,${(pf.image.data)}`}
                              />
                            ) : (
                              <img
                                alt="" className="img-fluid"
                                src={require(`../assets/images/project-img.jpg`).default}
                              />
                            )}
                          </div>
                          <div className="project-title">
                            <h6>{pf.name}</h6>
                          </div>
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </div>
          ) : (
            <></>
          )}

          {/* popup content */}
          <Modal
            isOpen={showPFModal}
            toggle={closePFModal}
            onClosed={closePFModal}
            id="portfolioModal"
            backdrop="static"
          >
            <ModalHeader cssModule={{ "modal-title": "w-100" }}>
              <p className="text-center mb-0">{pfFormData.name}</p>
              <Button className="close" onClick={closePFModal}>
                <span aria-hidden="true">&times;</span>
              </Button>
            </ModalHeader>
            <ModalBody>
              <div className="project-description-drag-drop">
                <div className="post">
                  <textarea
                    rows="4" style={{ resize: "none" }}
                    className="form-control text-area-exp bg-transparent border-0 shadow-none"
                    placeholder="Description"
                    value={pfFormData.description}
                    readOnly={true}
                  ></textarea>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <div className="text-center mt-2 w-100">
                <Button
                  color="secondary" className="w-25 mx-1 shadow-none"
                  onClick={closePFModal}
                >
                  Close
                </Button>
              </div>
            </ModalFooter>
          </Modal>
        </Container>
      </div>
    </>
  );
};

export default HireProfileView;