import React, { useContext, useEffect, useCallback, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FirebaseContext } from "../contexts";
import {
  Container,
  Row,
  Col,
  Input,
  Card,
  CardBody,
  Nav,
  NavItem,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  NavLink,
  Button,
} from "reactstrap";
import {
  wikiService,
  blogService,
  toastAlert,
  getFormattedDate,
  arrayBufferToBase64,
  chunkArrayInGroups,
} from "../Services";
// Core Components
import Header from "../components/Layout/Header";


const Wiki = () => {
  const maxWikiCards = 6;
  const { setLoading } = useContext(FirebaseContext);
  const [wikis, setWikis] = useState(null);
  const [wikiCategories, setWikiCategories] = useState([]);
  const [categorizedWikis, setCategorizedWikis] = useState([]);
  const [latestBlogs, setLatestBlogs] = useState(null);
  const [search, setSearch] = useState("");
  const [showMore, setShowMore] = useState(false);
  const [activeTab, setActiveTab] = useState("All");

  const searchParams = new URLSearchParams(useLocation().search);
  const type = searchParams.get("type");

  const toggleSubCatDropdown = (catId) => {
    let wikiCategoriesCopy = [...wikiCategories];
    wikiCategoriesCopy = wikiCategoriesCopy.map((cat) => {
      if (cat["key"] === catId) {
        cat["isOpen"] = !cat["isOpen"];
      }
      return cat;
    });
    setWikiCategories(wikiCategoriesCopy);
  };

  const toggleTabContent = (catId, subCatId) => {
    setActiveTab(catId);
    setShowMore(false);
    let catWikiArr = [...wikis];
    if (catId !== "All") {
      catWikiArr = catWikiArr.filter((wiki) => wiki.category === catId);
      if (subCatId) {
        catWikiArr = catWikiArr.filter((wiki) => wiki.subCategory === subCatId);
      }
    }
    setCategorizedWikis(catWikiArr);
  };

  const getPageData = useCallback(async () => {
    try {
      let wikiCategories = await wikiService.getWikiCategories();
      wikiCategories = wikiCategories.map((cat) => {
        cat["isOpen"] = false;
        return cat;
      });
      setWikiCategories(wikiCategories);
    } catch (error) {
      setWikiCategories([]);
      toastAlert("error", "Error while fetching wiki categories");
    }

    try {
      let wikis = await wikiService.getAllWikis();
      setWikis(wikis);
      if (type) {
        setActiveTab(type);
        let catWikiArr = [...wikis];
        if (type !== "All") {
          catWikiArr = catWikiArr.filter((wiki) => wiki.category === type);
        }
        setCategorizedWikis(catWikiArr);
      } else {
        setCategorizedWikis(wikis);
      }
    } catch (error) {
      setWikis([]);
      toastAlert("error", "Error while fetching wiki");
    }

    try {
      let latestBlogs = await blogService.getLatestBlogs({});
      setLatestBlogs(latestBlogs);
    } catch (error) {
      setLatestBlogs([]);
      toastAlert("error", "Error while fetching latest blogs");
    }
    setLoading(false);
  }, [setLoading, type]);

  useEffect(() => {
    document.body.classList.remove("white-bg");
    getPageData();
  }, [getPageData]);

  useEffect(() => {
    return () => {
      setLoading(true);
    };
  }, [setLoading]);

  return (
    <>
      <div className="header-nav-bg">
        <Header />
        <section>
          <div className="hero-section">
            <div className="text-center wow fadeInUp arrow-hero">
              <h1 className="hero-title">Wiki</h1>
              <h5 className="hero-t">
                Find your bots, server, proxies, groups, accounts …
              </h5>
              <div className="serach-section">
                <div className="search-inner">
                  <div className="first-part text-left">
                    <i className="fas fa-search"></i>
                    <Input
                      type="text"
                      className="search-input w-100 p-1 border-0 shadow-none"
                      key="one"
                      placeholder="Search"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>
                  <div className="third-part">
                    <a href="/#" className="search-a">
                      <div className="search-btn">
                        <i className="fas fa-search"></i>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section>
        <Container className="skill-section bots wow fadeInUp blog-section">
          <Row>
            <Col lg="9">
              <Nav pills className="mb-3" key="pills-tab" role="tablist">
                <NavItem>
                  <NavLink
                    className={`${activeTab === "All" ? "active" : ""}`}
                    onClick={() => { toggleTabContent("All") }}
                  >
                    All
                  </NavLink>
                </NavItem>
                {wikiCategories.map((cat, i) => {
                  return cat.subCategories.length ? (
                    <Dropdown
                      nav isOpen={cat.isOpen}
                      toggle={() => toggleSubCatDropdown(cat["key"])}
                      key={cat["key"]}
                    >
                      <DropdownToggle nav caret className="grey-bg-dropdown">
                        {cat["name"]}
                      </DropdownToggle>
                      <DropdownMenu className="grey-menu">
                        {chunkArrayInGroups(cat.subCategories, 4).map(
                          (subCatArr, j) => {
                            return (
                              <div key={j}>
                                {subCatArr.map((s, k) => {
                                  return (
                                    <DropdownItem key={k} onClick={() => { toggleTabContent(cat['key'], s['key']) }}>
                                      {s["name"]}
                                    </DropdownItem>
                                  );
                                })}
                              </div>
                            );
                          }
                        )}
                      </DropdownMenu>
                    </Dropdown>
                  ) : (
                    <NavItem key={i}>
                      <NavLink
                        className={`${activeTab === cat["key"] ? "active" : ""}`}
                        onClick={() => { toggleTabContent(cat["key"], null) }}
                      >
                        {cat["name"]}
                      </NavLink>
                    </NavItem>
                  );
                })}
              </Nav>

              <div className="">
                <div className="left-box-part box-icon mt-4">
                  <h4 className="skill-m-title d-inline-block ml-3 mb-5">
                    Most used
                  </h4>
                  <Button
                    className="contact-us-btn more-btn py-2 px-4 border-0 shadow-none float-right"
                    onClick={() => setShowMore(true)}
                  >
                    READ MORE
                  </Button>
                  <Container>
                    <Row>
                      {categorizedWikis.length ? (
                        categorizedWikis.slice(0, showMore ? categorizedWikis.length : maxWikiCards).map((wiki, i) => {
                          return (
                            <Col key={i} lg="4" md="6"
                              className={wiki.title.toLowerCase().includes(search.toLowerCase()) ? "mt-3" : "mt-3 d-none"}
                            >
                              <a href={wiki.url} target="_blank" rel="noreferrer">
                                <div className="logo-grey-box h-100">
                                  <div className="logo-img">
                                    <img
                                      alt="" className="img-fluid"
                                      src={`data:${wiki.image.contentType};base64,${arrayBufferToBase64(wiki.image.data.data)}`}
                                    />
                                  </div>
                                  <div className="logo-title">
                                    <h5 className="card-title news-title">
                                      {wiki.title}
                                    </h5>
                                    <p className="mt-2">{wiki.description}</p>
                                  </div>
                                </div>
                              </a>
                            </Col>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </Row>
                  </Container>
                </div>
              </div>
            </Col>

            <Col lg="3">
              <div className="right-news-part mt-4">
                <h4 className="skill-m-title d-inline-block">
                  Latest Articles
                </h4>
                {latestBlogs && latestBlogs.length ? (
                  latestBlogs.map((blog, i) => {
                    return (
                      <Link to={`/blog/${blog.url}`} key={i}>
                        <Card className="mb-3">
                          <img
                            alt="" className="card-img-top w-100 rounded-0"
                            src={`data:${blog.image.contentType};base64,${arrayBufferToBase64(blog.image.data.data)}`}
                          />
                          <CardBody className="p-0 mt-2">
                            <div className="grey-badge">
                              <span className="grey-bg">
                                {blog.category.toUpperCase()}
                              </span>
                              <span className="date">
                                {getFormattedDate(new Date(blog.updatedAt))}
                              </span>
                            </div>
                            <h5 className="card-title news-title text-left">
                              {blog.title}
                            </h5>
                            <p className="card-text news-text">
                              {blog.description}
                            </p>
                          </CardBody>
                        </Card>
                      </Link>
                    )
                  })
                ) : (
                  <></>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Wiki;