import React, { useContext, useEffect, useState } from 'react';
import { FirebaseContext } from '../contexts';
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from 'reactstrap';
// Core Components & Services
import { toastAlert } from '../Services';
import Header from '../components/Layout/Header';
// 
const axios = require('axios');
const API_SERVER_URL = process.env.REACT_APP_API_URL;


const ContactUs = () => {
  const [activeTab, setActiveTab] = useState('contactUs');
  const toggleTabContent = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  }

  const { setLoading } = useContext(FirebaseContext);
  useEffect(() => {
    document.body.classList.add('c-form-page');
    setLoading(false);
  }, [setLoading]);

  useEffect(() => {
    return () => {
      document.body.classList.remove('c-form-page');
      setLoading(true);
    }
  }, [setLoading]);

  const sendMessage = async (event, type) => {
    try {
      event.preventDefault();
      let formData = new FormData(event.target);
      formData.append('type', type);

      const config = {
        method: 'POST',
        url: `${API_SERVER_URL}/contact_us/sendMessage`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: formData
      };
      let response = await axios(config);
      toastAlert(response.data.status, response.data.message)
      event.target.reset();
    } catch (error) {
      toastAlert('Error', (error.response && error.response.data
        && error.response.data.message) || error.message);
    }
  }

  return (
    <>
      <div className="header-nav-bg">
        <Header />
        <section>
          <div className="hero-section contact-us-section">
            <div className="text-center wow fadeInUp hello-what-we-do">
              <h1 className="hero-title text-left mb-4 ">Hello, what do <br /> you want ?</h1>
              <div className="three-tab-section">
                <Nav pills className="mb-3" id="pills-tab" role="tablist">
                  <NavItem>
                    <NavLink
                      className={`${activeTab === 'contactUs' ? 'active' : ''}`}
                      onClick={() => { toggleTabContent('contactUs'); }}
                    >
                      contact us
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={`${activeTab === 'reportDeadLink' ? 'active' : ''}`}
                      onClick={() => { toggleTabContent('reportDeadLink'); }}
                    >
                      report dead link
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={`${activeTab === 'addNewLink' ? 'active' : ''}`}
                      onClick={() => { toggleTabContent('addNewLink'); }}
                    >
                      Add a new link
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
            </div>

            <TabContent activeTab={activeTab}>
              <TabPane tabId="contactUs" className="wow fadeInUp">
                <div className="contact-us-form">
                  <h5 className="contact-head">Contact us</h5>
                  <div className="form-section">
                    <Form id="contactUs" onSubmit={(event) => sendMessage(event, 'contactUs')}>
                      <FormGroup>
                        <Label for="name">Name</Label>
                        <Input
                          type="text"
                          name="name"
                          placeholder="Name"
                          required
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="email">Email</Label>
                        <Input
                          type="email"
                          name="email"
                          placeholder="exemple@gmail.com"
                          required
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="company">Company</Label>
                        <Input
                          type="text"
                          name="company"
                          placeholder="Your company"
                          required
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="message">Message</Label>
                        <Input
                          type="textarea"
                          name="message"
                          rows="3"
                          placeholder="Write your message"
                          required
                        />
                      </FormGroup>
                      <Button type="submit" className="btn-contact-yellow">SEND</Button>
                    </Form>
                  </div>
                </div>
              </TabPane>

              <TabPane tabId="reportDeadLink" className="wow fadeInUp">
                <div className="contact-us-form">
                  <h5 className="contact-head">Report dead link</h5>
                  <h6 className="mb-3 form-head-text">Help us keep the website clean</h6>
                  <div className="form-section">
                    <Form id="reportDeadLink" onSubmit={(event) => sendMessage(event, 'reportDeadLink')}>
                      <FormGroup>
                        <Label for="email">Email</Label>
                        <Input
                          type="email"
                          name="email"
                          placeholder="exemple@gmail.com"
                          required
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="deadLink">Dead link</Label>
                        <Input
                          type="url"
                          name="deadLink"
                          placeholder="url"
                          required
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="pageUrl">Page Url</Label>
                        <Input
                          type="text"
                          name="pageUrl"
                          placeholder="url"
                          required
                        />
                      </FormGroup>
                      <Button type="submit" className="btn-contact-yellow">SEND</Button>
                    </Form>
                  </div>
                </div>
              </TabPane>

              <TabPane tabId="addNewLink" className="wow fadeInUp">
                <div className="contact-us-form">
                  <h5 className="contact-head">Add a new link</h5>
                  <h6 className="mb-3 form-head-text">Missing a link? Complete this form and we'll look into it.</h6>
                  <div className="form-section">
                    <Form id="addNewLink" onSubmit={(event) => sendMessage(event, 'addNewLink')}>
                      <FormGroup>
                        <Label for="linkToAdd">Link to add</Label>
                        <Input
                          type="url"
                          name="linkToAdd"
                          placeholder="URL"
                          required
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="email">Email</Label>
                        <Input
                          type="email"
                          name="email"
                          placeholder="exemple@gmail.com"
                          required
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="company">Company</Label>
                        <Input
                          type="text"
                          name="company"
                          placeholder="Your company"
                          required
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="example">Example select</Label>
                        <Input type="select" name="example" required>
                          <option>1</option>
                          <option>2</option>
                          <option>3</option>
                          <option>4</option>
                          <option>5</option>
                        </Input>
                      </FormGroup>
                      <Button type="submit" className="btn-contact-yellow">SEND</Button>
                    </Form>
                  </div>
                </div>
              </TabPane>
            </TabContent>
          </div>
        </section>
      </div>
    </>
  )
}

export default ContactUs;