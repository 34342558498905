import React, { useContext, useEffect, useCallback, useState } from 'react';
import { useLocation, useHistory } from "react-router-dom";
import queryString from 'query-string';
import {
  Container,
  Row,
  Col,
  Input,
  Button
} from 'reactstrap';
import Select from 'react-select';
import Slider, { createSliderWithTooltip } from 'rc-slider';
import { FirebaseContext } from '../contexts';
import {
  devProfileList,
  countryList,
  languageList
} from '../Services/FreelancerHelper';
import {
  freelancerService,
  toastAlert,
} from '../Services';
// Core Components
import Header from '../components/Layout/Header';
import FreelancerCard from '../components/common/FreelancerCard';
const Range = createSliderWithTooltip(Slider.Range);


const HireSearch = () => {
  let siteHistory = useHistory();
  let siteLocation = useLocation();
  const { setLoading } = useContext(FirebaseContext);

  // Freelancers data
  const [query, setQueries] = useState({});
  const [flCount, setFlCount] = useState(0);
  const [freelancers, setFreelancers] = useState([]);

  // Freelancers Filters 
  const [profile, setProfile] = useState([]);
  const [location, setLocation] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [yearsOfExperience, setYearsOfExperience] = useState([0, 0]);
  const [availability, setAvailability] = useState([]);
  const [workplace, setWorkplace] = useState([]);

  const [sidebarMenuOpen, setSidebarMenuOpen] = useState(false);
  const toggleSidebarMenu = () => setSidebarMenuOpen(!sidebarMenuOpen);

  const experienceMarks = {
    0: {
      style: {
        color: "var(--main-blue)",
      },
      label: <strong>0</strong>,
    },
    1: {
      style: {
        color: "var(--main-blue)",
      },
      label: <strong>1+</strong>,
    },
    2: {
      style: {
        color: "var(--main-blue)",
      },
      label: <strong>2+</strong>,
    },
    3: {
      style: {
        color: "var(--main-blue)",
      },
      label: <strong>3+</strong>,
    },
    4: {
      style: {
        color: "var(--main-blue)",
      },
      label: <strong>4+</strong>,
    },
    5: {
      style: {
        color: "var(--main-blue)",
      },
      label: <strong>5+</strong>,
    },
    6: {
      style: {
        color: "var(--main-blue)",
      },
      label: <strong>6+</strong>,
    },
    7: {
      style: {
        color: "var(--main-blue)",
      },
      label: <strong>7+</strong>,
    },
    8: {
      style: {
        color: "var(--main-blue)",
      },
      label: <strong>8+</strong>,
    },
    9: {
      style: {
        color: "var(--main-blue)",
      },
      label: <strong>9+</strong>,
    },
    10: {
      style: {
        color: "var(--main-blue)",
      },
      label: <strong>10+</strong>,
    },
  };

  const getLatestFreelancers = useCallback(async (query) => {
    setLoading(true);
    try {
      let freelancers = await freelancerService.getFilteredFreelancers(query);
      setFreelancers(freelancers);
      setFlCount(freelancers.length);
    } catch (error) {
      toastAlert('error', 'Error while fetching freelancers');
    }
    setLoading(false);
  }, [setLoading]);

  const resetFilters = () => {
    setYearsOfExperience([0, 0]);
    siteHistory.replace({
      pathname: siteLocation.pathname,
      search: queryString.stringify({})
    });
  }

  const updateFilters = (type, value) => {
    const queryParams = queryString.parse(query);
    let modifiedQuery = JSON.parse(JSON.stringify(queryParams));

    switch (type) {
      case 'profile':
      case 'location':
      case 'languages':
        modifiedQuery = { ...queryParams, [type]: value };
        break;
      case 'availability':
      case 'workplace':
        let checkedValues = [...document.querySelectorAll(`input[name=${type}]:checked`)].map(e => e.value);
        modifiedQuery = { ...queryParams, [type]: checkedValues };
        break;
      case 'yearsOfExperience':
        let minExperience = value[0];
        let maxExperience = value[1];
        modifiedQuery = { ...queryParams, minExperience, maxExperience };
        break;
      default:
        return;
    }
    siteHistory.replace({
      pathname: siteLocation.pathname,
      search: queryString.stringify(modifiedQuery)
    });
  }

  const showFilters = useCallback((query) => {
    const QueryParams = queryString.parse(query);

    const profile = devProfileList.filter(dev => (QueryParams['profile'] || []).includes(dev['value']));
    const location = countryList.filter(ctr => (QueryParams['location'] || []).includes(ctr['value']));
    const languages = languageList.filter(lang => (QueryParams['languages'] || []).includes(lang['value']));
    const availability = QueryParams['availability'] || [];
    const workplace = QueryParams['workplace'] || [];
    const minExperience = QueryParams['minExperience'];
    const maxExperience = QueryParams['maxExperience'];
    if (!(isNaN(minExperience) && isNaN(maxExperience))) {
      setYearsOfExperience([parseInt(minExperience), parseInt(maxExperience)]);
    }

    setQueries(query);
    setProfile(profile);
    setLocation(location);
    setLanguages(languages);
    setAvailability(availability);
    setWorkplace(workplace);
    getLatestFreelancers(query);
  }, [getLatestFreelancers]);

  useEffect(() => {
    document.body.classList.add('white-bg');
    showFilters(siteLocation.search);
  }, [siteLocation, showFilters]);

  useEffect(() => {
    return () => {
      setLoading(true);
      document.body.classList.remove('white-bg');
    }
  }, [setLoading]);

  return (
    <>
      <div className="header-nav-bg">
        <Header />
        <section className="hire-search">
          <div id="wrapper" className={sidebarMenuOpen ? 'toggled' : ''}>
            <div id="sidebar-wrapper">
              <Button className="closebtn p-0 m-0 border-0 shadow-none bg-transparent text-main" onClick={toggleSidebarMenu}>&times;</Button>
              <div className="sidebar-heading">
                <div className="filter-title">
                  <Button
                    color="link"
                    className="text-main m-0 p-0 shadow-none"
                    onClick={resetFilters}>
                    Reset All Filters ✖
                  </Button>
                </div>

                <div className="filter-title">
                  <h5>Professions</h5>
                </div>
                <div className="checkbox-section">
                  <Select
                    id="profile" name="profile"
                    placeholder="Select Profession..."
                    className="react-select react-select-primary search-input w-100 p-0 border-0 shadow-none"
                    classNamePrefix="hire-search-react-select"
                    isMulti options={devProfileList}
                    value={profile}
                    onChange={(e) => updateFilters('profile', e.map(p => p.value))}
                  />
                </div>

                <div className="filter-title">
                  <h5>Locations</h5>
                </div>
                <div className="checkbox-section">
                  <Select
                    id="location" name="location"
                    placeholder="Select Location..."
                    className="react-select react-select-primary search-input w-100 p-0 border-0 shadow-none"
                    classNamePrefix="hire-search-react-select"
                    isMulti options={countryList}
                    value={location}
                    onChange={(e) => updateFilters('location', e.map(l => l.value))}
                  />
                </div>

                <div className="filter-title">
                  <h5>Languages</h5>
                </div>
                <div className="checkbox-section">
                  <Select
                    id="languages" name="languages"
                    placeholder="Select Language..."
                    className="react-select react-select-primary search-input w-100 p-0 border-0 shadow-none"
                    classNamePrefix="hire-search-react-select"
                    isMulti options={languageList}
                    value={languages}
                    onChange={(e) => updateFilters('languages', e.map(l => l.value))}
                  />
                </div>

                <div className="filter-title">
                  <h5>Level of experience</h5>
                </div>
                <div className="checkbox-section">
                  <Range
                    className="mb-4"
                    min={0} max={10} step={1}
                    defaultValue={[0, 0]}
                    value={yearsOfExperience}
                    tipFormatter={(value) => `${value} ${value ? '+ years' : ' year'}`}
                    marks={experienceMarks}
                    onChange={(e) => updateFilters('yearsOfExperience', e)}
                  />

                  {/* <div className="checkbox-div">
                    <label className="textarea">
                      <span name="yearsOfExperience">
                        <Input
                          type="checkbox"
                          name="yearsOfExperience"
                          value={[0, 2]}
                        />
                      </span>
                      <span>0 - 2 years</span>
                    </label>
                  </div>
                  <div className="checkbox-div">
                    <label className="textarea">
                      <span name="yearsOfExperience">
                        <Input
                          type="checkbox"
                          name="yearsOfExperience"
                          value={[2, 5]}
                        />
                      </span>
                      <span>2 - 5 years</span>
                    </label>
                  </div>
                  <div className="checkbox-div">
                    <label className="textarea">
                      <span name="yearsOfExperience">
                        <Input
                          type="checkbox"
                          name="yearsOfExperience"
                          value={[5, 25]}
                        />
                      </span>
                      <span>5 years and +</span>
                    </label>
                  </div> */}
                </div>

                <div className="filter-title">
                  <h5>Availability</h5>
                </div>
                <div className="checkbox-section">
                  <div className="checkbox-div">
                    <label className="textarea">
                      <span name="availability">
                        <Input
                          type="checkbox"
                          name="availability"
                          value="fullTime"
                          checked={availability.includes('fullTime')}
                          onChange={(e) => updateFilters('availability', e.target.value)}
                        />
                      </span>
                      <span>Full time</span>
                    </label>
                  </div>
                  <div className="checkbox-div">
                    <label className="textarea">
                      <span name="availability">
                        <Input
                          type="checkbox"
                          name="availability"
                          value="partTime"
                          checked={availability.includes('partTime')}
                          onChange={(e) => updateFilters('availability', e.target.value)}
                        />
                      </span>
                      <span>Part time</span>
                    </label>
                  </div>
                </div>

                <div className="filter-title">
                  <h5>Workplace</h5>
                </div>
                <div className="checkbox-section">
                  <div className="checkbox-div">
                    <label className="textarea">
                      <span name="workplace">
                        <Input
                          type="checkbox"
                          name="workplace"
                          value="atRemote"
                          checked={workplace.includes('atRemote')}
                          onChange={(e) => updateFilters('workplace', e.target.value)}
                        />
                      </span>
                      <span>Remote</span>
                    </label>
                  </div>
                  <div className="checkbox-div">
                    <label className="textarea">
                      <span name="workplace">
                        <Input
                          type="checkbox"
                          name="workplace"
                          value="atCustomer"
                          checked={workplace.includes('atCustomer')}
                          onChange={(e) => updateFilters('workplace', e.target.value)}
                        />
                      </span>
                      <span>At the customer</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div id="page-content-wrapper">
              <Container className="search-result mt-5">
                <div className="freelancer-found text-center wow fadeInUp">
                  <span className="freelancer-count">{flCount} </span> Freelancer found
                </div>
                <span className="filter-text" onClick={toggleSidebarMenu}>
                  <img
                    alt=""
                    src={require(`../assets/images/filter.png`).default}
                  /> FILTER
                  </span>
                <Row className="wow fadeInUp">
                  {freelancers && freelancers.length ?
                    (freelancers.map((freelancer, i) => {
                      return (
                        <Col sm="12" md="6" lg="4" className="mt-3" key={i}>
                          <FreelancerCard freelancer={freelancer} />
                        </Col>
                      )
                    })
                    ) : (
                      <></>
                    )
                  }
                </Row>
              </Container>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default HireSearch;