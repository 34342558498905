const devProfileList = [
  {
    value: "Frontend Developer",
    label: "Frontend Developer"
  },
  {
    value: "Backend Developer",
    label: "Backend Developer"
  },
  {
    value: "Fullstack Developer",
    label: "Fullstack Developer"
  },
  {
    value: "Web Developer",
    label: "Web Developer"
  },
  {
    value: "Graphics Designer",
    label: "Graphics Designer"
  },
  {
    value: "UX Designer",
    label: "UX Designer"
  },
  {
    value: "Mobile Developer",
    label: "Mobile Developer"
  },
  {
    value: "Game Developer",
    label: "Game Developer"
  },
  {
    value: "Software Developer",
    label: "Software Developer"
  },
  {
    value: "Data Scientist",
    label: "Data Scientist"
  },
  {
    value: "DevOps Developer",
    label: "DevOps Developer"
  },
  {
    value: "Embedded Developer",
    label: "Embedded Developer"
  },
  {
    value: "Security Developer",
    label: "Security Developer"
  },
];

export {
  devProfileList
};