import React, { useState, useContext, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  Container,
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap';
import { FirebaseContext } from '../../contexts';


const Header = () => {
  const { user, firebase } = useContext(FirebaseContext);
  const [isOpen, setIsOpen] = useState(false);
  const toggleNavbar = () => setIsOpen(!isOpen);
  const whiteLogo = 'Logo-SneackersnCo_Large.png';
  const blackLogo = 'black-logo.png';
  const [Logo, setLogo] = useState(whiteLogo);
  const location = useLocation();
  const [pathname, setPathname] = useState('/');

  useEffect(() => {
    let pathname = location.pathname;
    setPathname(pathname);
    if (['/', '/wiki', '/hire', '/contact-us'].indexOf(pathname) !== -1) {
      setLogo(whiteLogo);
    } else {
      setLogo(blackLogo);
    }
  }, [location]);

  return (
    <header>
      <Container>
        <Navbar className="row align-items-center" light expand="lg">
          <NavbarBrand to="/" tag={Link}>
            <img
              alt="Sneackers Community"
              className="logo"
              src={require(`../../assets/images/${Logo}`).default}
            />
          </NavbarBrand>
          <NavbarToggler className={isOpen ? "" : ""} onClick={toggleNavbar}>
            <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 67 59">
              <g id="mobile-toggle" transform="translate(-4267 3487)">
                <rect id="Rectangle_79" data-name="Rectangle 79" width="67" height="59" rx="15"
                  transform="translate(4267 -3487)" fill="#f0f0f0" />
                <rect id="Rectangle_81" data-name="Rectangle 81" width="19" height="3" rx="1.5"
                  transform="translate(4286 -3459)" />
                <rect id="Rectangle_83" data-name="Rectangle 83" width="30" height="3" rx="1.5"
                  transform="translate(4286 -3470)" />
                <rect id="Rectangle_82" data-name="Rectangle 82" width="26" height="3" rx="1.5"
                  transform="translate(4286 -3448)" />
              </g>
            </svg>
          </NavbarToggler>
          <Collapse isOpen={isOpen} navbar id="navbarNav">
            <Nav className="ml-auto" navbar>
              <NavItem active={pathname === '/' ? true : false}>
                <NavLink to="/" tag={Link}>Home</NavLink>
              </NavItem>
              <NavItem active={pathname === '/wiki' ? true : false}>
                <NavLink to="/wiki" tag={Link}>Wiki</NavLink>
              </NavItem>
              <NavItem active={pathname === '/hire' ? true : false}>
                <NavLink to="/hire" tag={Link}>Hire</NavLink>
              </NavItem>
              <NavItem active={pathname === '/blog' ? true : false}>
                <NavLink to="/blog" tag={Link}>Blog</NavLink>
              </NavItem>
              <NavItem active={pathname === '/contact-us' ? true : false}>
                <NavLink to="/contact-us" tag={Link}>Contact us</NavLink>
              </NavItem>
              {user ?
                (<>
                  <NavItem>
                    <NavLink className="sign-up-btn mr-2" to="/dashboard" tag={Link}>Dashboard</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className="sign-up-btn" onClick={firebase.doSignOut}>Logout</NavLink>
                  </NavItem>
                </>) : (<>
                  <NavItem active={pathname === '/login' ? true : false}>
                    <NavLink to="/login" tag={Link}>Log in</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className="sign-up-btn" to="/sign-up" tag={Link}>Sign up</NavLink>
                  </NavItem>
                </>)
              }
            </Nav>
          </Collapse>
        </Navbar>
      </Container>
    </header>
  )
}

export default Header;