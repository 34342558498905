import React, { createContext, useState, useEffect } from 'react';
import firebase from '../Services/Firebase';
import Loader from '../components/Layout/Loader';
const FirebaseContext = createContext();


const FirebaseProvider = ({ children }) => {
  const [user, setUser] = useState(firebase.auth.currentUser);
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    firebase.auth.onAuthStateChanged((user) => {
      setUser(user);
      setAuthenticated(true);
    });
  }, [authenticated]);

  return (
    <>
      {loading && <Loader/>}
      <FirebaseContext.Provider value={{ user, authenticated, firebase, setUser, setLoading }}>
        {children}
      </FirebaseContext.Provider>
    </>
  );
}

export {
  FirebaseContext,
  FirebaseProvider
};