import {
  toast
} from 'react-toastify';

/*
  Function that show toast alert based on type(success,error,info)
 */
const toastAlert = (type, message) => {
  if (type.toLowerCase() === 'info') {
    toast.info(message);
  } else if (type.toLowerCase() === 'success') {
    toast.success(message);
  } else if (type.toLowerCase() === 'warn') {
    toast.warn(message);
  } else if (type.toLowerCase() === 'error') {
    toast.error(message);
  } else {
    toast(message);
  }
}

export {
  toastAlert,
};