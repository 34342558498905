import React, { useState, useContext, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { FirebaseContext } from "../contexts";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
} from "reactstrap";
// Core Components
import Header from "../components/Layout/Header";
import { userService, toastAlert } from "../Services";

const Signup = () => {
  const { firebase, setUser, setLoading } = useContext(FirebaseContext);
  const history = useHistory();
  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    createPassword: "",
    confirmPassword: "",
  });
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    (async function () {
      firebase.auth.onAuthStateChanged(async (user) => {
        if (user && user.emailVerified) {
          history.push("/");
          return;
        } else {
          setLoading(false);
        }
      });
    })();
    document.body.classList.add("white-bg");
  }, [setLoading, history, firebase]);

  useEffect(() => {
    return () => {
      document.body.classList.remove("white-bg");
    };
  }, []);

  const handleChange = (event) => {
    // event.persist();
    event.preventDefault();
    let { name, value } = event.target;
    value = value.trim();

    let errors = { ...formErrors };
    const validEmailRegex = RegExp(
      /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/i
    );

    switch (name) {
      case "firstName":
      case "lastName":
        errors[name] =
          value.length < 3 ? `${name} must be 3 characters long!` : "";
        break;
      case "email":
        errors[name] = !validEmailRegex.test(value)
          ? "Email is not valid!"
          : "";
        break;
      case "createPassword":
        errors[name] =
          value.length < 6 ? "Password must be 6 characters long!" : "";
        errors["confirmPassword"] =
          value && value !== formValues.confirmPassword
            ? "Password and Confirm password does not match!"
            : "";
        break;
      case "confirmPassword":
        errors[name] =
          value !== formValues.createPassword
            ? "Password and Confirm password does not match!"
            : "";
        break;
      default:
        break;
    }
    setFormValues((values) => ({ ...values, [name]: value }));
    setFormErrors((_) => ({ ...errors }));
  };

  const validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
    return valid;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm(formErrors)) {
      firebase.doCreateUserWithEmailAndPassword(formValues.email, formValues.createPassword)
        .then(async (resUser) => {
          setUser(null);
          await firebase.doUpdateDisplayName(resUser.user, `${formValues.firstName} ${formValues.lastName}`);
          await firebase.doSendEmailVerification(resUser.user);
          let idToken = await firebase.auth.currentUser.getIdToken(true);
          await userService.createUser({ idToken }).catch((error) => {
            throw new Error(error.message);
          });
          toastAlert("info", "We have sent an email with a confirmation link to your email address. In order to complete the sign-up process, please click the confirmation link.");
          return resUser.user;
        })
        .then((user) => {
          firebase.doSignOut();
          setUser(null);
          history.push("/login");
        })
        .catch((error) => {
          toastAlert("error", error.message);
        });
    }
  };

  return (
    <div className="header-nav-bg">
      <Header />
      <section>
        <div className="signup-section width">
          <div className="text-center wow fadeInUp">
            <h1 className="main-title">Welcome !</h1>
            <Container className="form-section mt-4">
              <Form onSubmit={handleSubmit}>
                <Row className="form-row">
                  <Col md="6" className="mb-4">
                    <FormGroup className="mb-0">
                      <Input
                        type="text"
                        name="firstName"
                        className="form-control"
                        placeholder="First name"
                        value={formValues.firstName}
                        onChange={handleChange}
                        invalid={!!formErrors.firstName}
                        required
                      />
                      <FormFeedback invalid={formErrors.firstName}>
                        {formErrors.firstName}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col md="6" className="mb-4">
                    <FormGroup className="mb-0">
                      <Input
                        type="text"
                        name="lastName"
                        className="form-control"
                        placeholder="Last name"
                        value={formValues.lastName}
                        onChange={handleChange}
                        invalid={!!formErrors.lastName}
                        required
                      />
                      <FormFeedback invalid={formErrors.lastName}>
                        {formErrors.lastName}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="form-row">
                  <Col md="12" className="mb-4">
                    <FormGroup className="mb-0">
                      <Input
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        value={formValues.email}
                        onChange={handleChange}
                        invalid={!!formErrors.email}
                        required
                      />
                      <FormFeedback invalid={formErrors.email}>
                        {formErrors.email}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="form-row">
                  <Col md="6" className="mb-4">
                    <FormGroup className="mb-0">
                      <Input
                        type="password"
                        name="createPassword"
                        className="form-control"
                        placeholder="Create Password"
                        value={formValues.createPassword}
                        onChange={handleChange}
                        invalid={!!formErrors.createPassword}
                        required
                      />
                      <FormFeedback invalid={formErrors.createPassword}>
                        {formErrors.createPassword}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col md="6" className="mb-4">
                    <FormGroup className="mb-0">
                      <Input
                        type="password"
                        name="confirmPassword"
                        className="form-control"
                        placeholder="Confirm Password"
                        value={formValues.confirmPassword}
                        onChange={handleChange}
                        invalid={!!formErrors.confirmPassword}
                        required
                      />
                      <FormFeedback invalid={formErrors.confirmPassword}>
                        {formErrors.confirmPassword}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup>
                  <div className="form-check form-text">
                    <Input
                      type="checkbox"
                      name="agreeTOS"
                      className="form-check-input"
                      required
                    />
                    <Label className="form-check-label" for="agreeTOS">
                      I have read and agree to the Terms & Conditions
                    </Label>
                  </div>
                </FormGroup>
                <Button type="submit" className="btn-next shadow-none">
                  Next
                </Button>
                <p className="ac-text mt-4">
                  I have already an account <Link to="/login"> Log in </Link>
                </p>
              </Form>
            </Container>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Signup;
