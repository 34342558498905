import React, { useContext, useEffect, useCallback, useState } from 'react';
import { useParams, useLocation, Link } from 'react-router-dom';
import { FirebaseContext } from '../contexts';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody
} from 'reactstrap';
import {
  blogService,
  toastAlert,
  getFormattedDate,
  arrayBufferToBase64
} from '../Services';
// Core Components
import Header from '../components/Layout/Header';


const BlogArticle = () => {
  let { blogUrl } = useParams();
  const { setLoading } = useContext(FirebaseContext);
  const [blog, setBlog] = useState(null);
  const [latestBlogs, setLatestBlogs] = useState(null);

  const getBlogInfo = async (data) => {
    try {
      let blog = await blogService.getBlogByProperty(data);
      setBlog(blog);
    } catch (error) {
      setBlog({});
      throw new Error(error.message || 'Error while fetching blog info!');
    }
  }

  const getLatestBlogs = async () => {
    try {
      let latestBlogs = await blogService.getLatestBlogs({});
      setLatestBlogs(latestBlogs);
    } catch (error) {
      setLatestBlogs([]);
      throw new Error(error.message || 'Error while fetching latest articles!');
    }
  }

  const getPageData = useCallback(() => {
    setLoading(true);
    let data = { 'property': 'url', 'value': blogUrl };
    Promise.all([getBlogInfo(data), getLatestBlogs()])
      .then(() => { setLoading(false) })
      .catch(error => {
        setLoading(false);
        toastAlert('error', error.message || 'Error while fetching blog info!');
      });
  }, [setLoading, blogUrl]);

  const location = useLocation();
  useEffect(() => {
    document.body.classList.add('white-bg');
    document.getElementsByClassName('footer-bg') &&
      document.getElementsByClassName('footer-bg')[0] &&
      document.getElementsByClassName('footer-bg')[0].classList.add('mt150');
    getPageData();
  }, [location, getPageData]);

  useEffect(() => {
    return () => {
      document.body.classList.remove('white-bg');
      document.getElementsByClassName('footer-bg') &&
        document.getElementsByClassName('footer-bg')[0] &&
        document.getElementsByClassName('footer-bg')[0].classList.remove('mt150');
      setLoading(true);
    }
  }, [setLoading]);

  return (
    <>
      <div className="header-nav-bg">
        <Header />
        <section>
          {blog && Object.keys(blog).length ? (<>
            <Container>
              <Row className="blog-title blog-section">
                <Col md="12" className="text-left">
                  <h1 className="blog-inner-title">
                    {blog.title}
                  </h1>
                  <div className="date-blue">
                    <span>{blog.createdBy}</span> {getFormattedDate(new Date(blog.updatedAt))}
                  </div>
                  <div className="grey-badge mt-2">
                    <span className="grey-bg">{blog.category}</span>
                  </div>
                </Col>
              </Row>
            </Container>
            <Container fluid className="p-0 mt-4">
              <img
                alt=""
                className="img-fluid w-100"
                src={`data:${blog.image.contentType};base64,${arrayBufferToBase64(blog.image.data.data)}`}
              />
            </Container>
            <Container>
              <div className="artical-text mt-4 artical-inner ql-editor" dangerouslySetInnerHTML={{ __html: blog.content }} />
            </Container>
          </>) : (<></>)}

          <Container>
            <Row className="mt-5">
              <Col sm="12">
                <h4 className="last-artical-head">Last article</h4>
              </Col>

              {latestBlogs && latestBlogs.length && latestBlogs.map((lb, i) => {
                return (
                  <Col md="4" key={i}>
                    <Link to={`/blog/${lb.url}`}>
                      <Card className="mb-3">
                        <img
                          alt="..."
                          className="card-img-top w-100 rounded-0"
                          src={`data:${lb.image.contentType};base64,${arrayBufferToBase64(lb.image.data.data)}`}
                        />
                        <CardBody className="card-body p-0 mt-2">
                          <div className="grey-badge">
                            <span className="grey-bg">
                              {lb.category.toUpperCase()}
                            </span>
                            <span className="date">
                              {getFormattedDate(new Date(lb.updatedAt))}
                            </span>
                          </div>
                          <h5 className="card-title news-title text-left">
                            {lb.title}
                          </h5>
                          <p className="card-text news-text">
                            {lb.description}
                          </p>
                        </CardBody>
                      </Card>
                    </Link>
                  </Col>
                )
              })}
            </Row>
          </Container>
        </section>
      </div>
    </>
  )
}

export default BlogArticle;