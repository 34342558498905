const axios = require('axios');
const API_SERVER_URL = process.env.REACT_APP_API_URL;

class FreelancerService {
  async getMyData(data) {
    try {
      const config = {
        method: 'POST',
        url: `${API_SERVER_URL}/freelancers/@me`,
        headers: {
          contentType: 'application/json'
        },
        data: data
      };
      let response = await axios(config);
      if (response.status === 200 && response.data.status === 'success') {
        return response.data.data;
      } else {
        throw new Error(response.data.message || 'Error while fetching profile data');
      }
    } catch (error) {
      throw new Error(error.message || 'Error while fetching profile data');
    }
  }

  async updateData(data) {
    try {
      const config = {
        method: 'POST',
        url: `${API_SERVER_URL}/freelancers/update`,
        headers: {
          contentType: 'application/json'
        },
        data: data
      };
      let response = await axios(config);
      if (response.status === 200 && response.data.status === 'success') {
        return response.data.data;
      } else {
        throw new Error(response.data.message || 'Error while updating profile data');
      }
    } catch (error) {
      let errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || 'Error while updating profile data';
      throw new Error(errorMessage);
    }
  }

  async getAllFreelancers() {
    try {
      const config = {
        method: 'GET',
        url: `${API_SERVER_URL}/freelancers/getAll?sort=createdAt:desc`,
      };
      let response = await axios(config);
      if (response.status === 200 && response.data.status === 'success') {
        return response.data.data;
      } else {
        throw new Error(response.data.message || 'Error while fetching freelancers');
      }
    } catch (error) {
      let errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || 'Error while fetching freelancers';
      throw new Error(errorMessage);
    }
  }

  async getLatestFreelancers({ sort = "createdAt:desc", offset = 0, limit = 3 }) {
    try {
      let url = `${API_SERVER_URL}/freelancers/getAll`;
      if (offset) {
        url += `?offset=${offset}`;
      }
      if (limit) {
        url += `?limit=${limit}`;
      }
      if (sort) {
        url += `?sort=${sort}`;
      }

      const config = {
        method: 'GET',
        url: url,
      };
      let response = await axios(config);
      if (response.status === 200 && response.data.status === 'success') {
        return response.data.data;
      } else {
        throw new Error(response.data.message || 'Error while fetching freelancers');
      }
    } catch (error) {
      let errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || 'Error while fetching freelancers';
      throw new Error(errorMessage);
    }
  }

  async getFilteredFreelancers(query) {
    try {
      let url = `${API_SERVER_URL}/freelancers/getFiltered`;
      url = query ? `${url}${query}` : url;

      const config = {
        method: 'GET',
        url: url,
      };
      let response = await axios(config);
      if (response.status === 200 && response.data.status === 'success') {
        return response.data.data;
      } else {
        throw new Error(response.data.message || 'Error while fetching filtered freelancers');
      }
    } catch (error) {
      let errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || 'Error while fetching filtered freelancers';
      throw new Error(errorMessage);
    }
  }

  async getFreelancerByUID(data) {
    try {
      const config = {
        method: 'POST',
        url: `${API_SERVER_URL}/freelancers/getByUID`,
        data: data,
        headers: {
          contentType: 'application/json'
        }
      };
      let response = await axios(config);
      if (response.status === 200 && response.data.status === 'success') {
        return response.data.data;
      } else {
        throw new Error(response.data.message || 'Error while fetching freelancer profile');
      }
    } catch (error) {
      let errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || 'Error while fetching freelancer profile';
      throw new Error(errorMessage);
    }
  }
}

const freelancerService = new FreelancerService();
export {
  freelancerService
};