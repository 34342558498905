import React, { useState, useContext, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FirebaseContext } from '../contexts';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  FormFeedback
} from 'reactstrap';
// Core Components
import Header from '../components/Layout/Header';
import {
  toastAlert
} from '../Services';


const ForgotPassword = () => {
  const { firebase, setLoading } = useContext(FirebaseContext);
  const history = useHistory();
  const [formValues, setFormValues] = useState({
    email: ''
  });
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    (async function () {
      firebase.auth.onAuthStateChanged(async (user) => {
        if (user) {
          history.push('/');
          return;
        } else {
          setLoading(false);
        }
      });
    })();
    document.body.classList.add('white-bg');
  }, [setLoading, history, firebase.auth]);

  useEffect(() => {
    return () => {
      document.body.classList.remove('white-bg');
    }
  }, []);

  const handleChange = (event) => {
    // event.persist();
    event.preventDefault();
    let { name, value } = event.target;
    value = value.trim();

    let errors = { ...formErrors };
    const validEmailRegex = RegExp(/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/i);

    switch (name) {
      case 'email':
        errors[name] = !validEmailRegex.test(value) ? 'Email is not valid!' : '';
        break;
      default:
        break;
    }
    setFormValues(values => ({ ...values, [name]: value }));
    setFormErrors(_ => ({ ...errors }));
  }

  const validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach(
      (val) => val.length > 0 && (valid = false)
    );
    return valid;
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm(formErrors)) {
      firebase.doPasswordReset(formValues.email)
        .then(() => {
          toastAlert('success', 'Password reset link has been sent to your email address!');
          history.push('/login');
        })
        .catch(error => {
          toastAlert('error', error.message);
        });
    }
  }

  return (
    <div className="header-nav-bg">
      <Header />
      <section>
        <div className="signup-section login-section">
          <div className="text-center wow fadeInUp">
            <h1 className="main-title password">Trouble Logging In?</h1>
            <Container className="form-section mt-5">
              <Form onSubmit={handleSubmit}>
                <Row className="form-row">
                  <Col sm="12" className="mb-4">
                    <FormGroup className="mb-0">
                      <Input
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder="Enter Your Email"
                        value={formValues.email}
                        onChange={handleChange}
                        invalid={!!formErrors.email}
                        required
                      />
                      <FormFeedback invalid={formErrors.email}>{formErrors.email}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Button type="submit" className="btn-next mt-3">
                  Send Password Reset Link
              </Button>
                <p className="ac-text mt-4">Or Create New Account <Link to="/sign-up"> Sign Up </Link></p>
              </Form>
            </Container>
          </div>
        </div>
      </section>
    </div>
  )
}

export default ForgotPassword;