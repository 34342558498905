import React, { useContext, useEffect, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { FirebaseContext } from '../contexts';
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody
} from 'reactstrap';
// Core Components
import Header from '../components/Layout/Header';
import FreelancerCard from '../components/common/FreelancerCard';
import {
  DOM,
  wikiService,
  freelancerService,
  blogService,
  toastAlert,
  getFormattedDate,
  arrayBufferToBase64
} from '../Services';


const Home = () => {
  const { setLoading } = useContext(FirebaseContext);
  const [wikis, setWikis] = useState(null);
  const [freelancers, setFreelancers] = useState(null);
  const [blogs, setBlogs] = useState(null);

  const getPageData = useCallback(async () => {
    try {
      let wikis = await wikiService.getLatestWikis({ limit: 2 });
      setWikis(wikis);
    } catch (error) {
      setWikis([]);
      toastAlert('error', 'Error while fetching wiki');
    }

    try {
      let freelancers = await freelancerService.getLatestFreelancers({ limit: 6 });
      setFreelancers(freelancers);
    } catch (error) {
      setFreelancers([]);
      toastAlert('error', 'Error while fetching freelancers');
    }

    try {
      let blogs = await blogService.getLatestBlogs({ limit: 3 });
      setBlogs(blogs);
    } catch (error) {
      setBlogs([]);
      toastAlert('error', 'Error while fetching news');
    }
    setLoading(false);
  }, [setLoading]);

  useEffect(() => {
    getPageData();
  }, [getPageData]);

  useEffect(() => {
    return () => {
      setLoading(true);
    }
  }, [setLoading]);

  return (
    <>
      <div className="header-nav-bg">
        <Header />
        <section>
          <Container className="home-hero-section">
            <div className="text-center wow fadeInLeft">
              <h1 className="hero-title text-md-left">
                FIND YOUR <br className="hide-br" /> HAPPINESS
              </h1>
              <h5 className="hero-t home-hero-txt text-md-left">
                Looking for something in the sneaker community? <br /> We got you
              </h5>
            </div>
            <div className="home-img-part wow fadeInRight">
              <img
                alt=""
                className="img-fluid"
                src={require(`../assets/images/Scene-Home.png`).default}
              />
            </div>
          </Container>
          <div className="home-arrow text-center">
            <img
              alt=""
              className="arrow-img"
              src={require(`../assets/images/icon-down.svg`).default}
            />
          </div>
        </section>
      </div>

      <section>
        <Container className="what-do-section wow fadeInUp">
          <div className="what-do-head">
            <h4 className="sub-head">What do you want ?</h4>
            <p className="sub-text">Lorem ipsum Semper momentum</p>
          </div>
          <Row>
            <Col lg="5">
              {wikis && wikis.length ?
                (wikis.map((wiki, i) => {
                  return (
                    <a href={wiki.url} target="_blank" rel="noopener noreferrer" key={i}>
                      <div className="proxy-box mb-4">
                        <div className="proxy-img">
                          <img
                            alt="" className="img-fluid"
                            src={`data:${wiki.image.contentType};base64,${arrayBufferToBase64(wiki.image.data.data)}`}
                          />
                        </div>
                        <div className="proxy-text">
                          <h5 className="blue text-capitalize">
                            {wiki.category}
                          </h5>
                          <h5 className="proxy-title">
                            {wiki.title}
                          </h5>
                          <p className="m-0">
                            {wiki.description}
                          </p>
                        </div>
                      </div>
                    </a>
                  )
                })) : (<></>)
              }
            </Col>

            <Col lg="7" className="proxy-btn-part">
              <Link to="/wiki">
                <div className="proxy-buttons">
                  <div className="p-btn">
                    <span>All</span>
                    <span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="15.543" height="28.086" viewBox="0 0 15.543 28.086">
                        <path id="Path_52" data-name="Path 52" d="M-4300.393,8953l11.922,11.922-11.922,11.922"
                          transform="translate(4302.514 -8950.879)" fill="none" stroke="#000" strokeLinecap="round"
                          strokeLinejoin="round" strokeWidth="3" />
                      </svg>
                    </span>
                  </div>
                </div>
              </Link>
              <Link to="/wiki?type=accounts">
                <div className="proxy-buttons">
                  <div className="p-btn">
                    <span>Accounts</span>
                    <span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="15.543" height="28.086" viewBox="0 0 15.543 28.086">
                        <path id="Path_52" data-name="Path 52" d="M-4300.393,8953l11.922,11.922-11.922,11.922"
                          transform="translate(4302.514 -8950.879)" fill="none" stroke="#000" strokeLinecap="round"
                          strokeLinejoin="round" strokeWidth="3" />
                      </svg>
                    </span>
                  </div>
                </div>
              </Link>
              <Link to="/wiki?type=proxies">
                <div className="proxy-buttons">
                  <div className="p-btn">
                    <span>Proxies</span> <span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="15.543" height="28.086" viewBox="0 0 15.543 28.086">
                        <path id="Path_52" data-name="Path 52" d="M-4300.393,8953l11.922,11.922-11.922,11.922"
                          transform="translate(4302.514 -8950.879)" fill="none" stroke="#000" strokeLinecap="round"
                          strokeLinejoin="round" strokeWidth="3" />
                      </svg>
                    </span>
                  </div>
                </div>
              </Link>
              <Link to="/wiki?type=others">
                <div className="proxy-buttons">
                  <div className="p-btn">
                    <span>Others</span> <span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="15.543" height="28.086" viewBox="0 0 15.543 28.086">
                        <path id="Path_52" data-name="Path 52" d="M-4300.393,8953l11.922,11.922-11.922,11.922"
                          transform="translate(4302.514 -8950.879)" fill="none" stroke="#000" strokeLinecap="round"
                          strokeLinejoin="round" strokeWidth="3" />
                      </svg>
                    </span>
                  </div>
                </div>
              </Link>
              <Link to="/wiki?type=servers">
                <div className="proxy-buttons">
                  <div className="p-btn">
                    <span>Servers</span> <span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="15.543" height="28.086" viewBox="0 0 15.543 28.086">
                        <path id="Path_52" data-name="Path 52" d="M-4300.393,8953l11.922,11.922-11.922,11.922"
                          transform="translate(4302.514 -8950.879)" fill="none" stroke="#000" strokeLinecap="round"
                          strokeLinejoin="round" strokeWidth="3" />
                      </svg>
                    </span>
                  </div>
                </div>
              </Link>
              <Link to="/wiki?type=bots">
                <div className="proxy-buttons">
                  <div className="p-btn">
                    <span>Bots</span> <span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="15.543" height="28.086" viewBox="0 0 15.543 28.086">
                        <path id="Path_52" data-name="Path 52" d="M-4300.393,8953l11.922,11.922-11.922,11.922"
                          transform="translate(4302.514 -8950.879)" fill="none" stroke="#000" strokeLinecap="round"
                          strokeLinejoin="round" strokeWidth="3" />
                      </svg>
                    </span>
                  </div>
                </div>
              </Link>
            </Col>
          </Row>
        </Container>
      </section>

      <section>
        <div className="find-freelancer mt100 wow fadeInUp">
          <Container className="what-do-section">
            <div className="what-do-head">
              <h4 className="sub-head mt-0">What do you want ?</h4>
              <p className="sub-text mb-0">Lorem ipsum Semper momentum</p>
            </div>
            <Row>
              {freelancers && freelancers.length ?
                (freelancers.map((freelancer, i) => {
                  return (
                    <Col md="6" sm="3" lg="4" className="mt-3" key={i}>
                      <FreelancerCard freelancer={freelancer} />
                    </Col>
                  )
                })
                ) : (
                  <></>
                )
              }
            </Row>
            <Row className="mt-3">
              <Col className="text-center">
                <Link to="/hire-search">
                  <Button color="primary" className="contact-us-btn more-btn py-2 px-4">
                    ALL PROFILE
                  </Button>
                </Link>
              </Col>
            </Row>
          </Container>
        </div>
      </section>

      <section>
        <div className="news-section mt100 wow fadeInUp">
          <Container>
            <div className="what-do-head">
              <h4 className="sub-head mt-0">News</h4>
            </div>
            <div className="news-box-part mt-4">
              <Row>
                {blogs && blogs.length ?
                  (blogs.map((blog, i) => {
                    return (
                      <Col md="6" sm="3" lg="4" key={i}>
                        <Card to={`/blog/${blog.url}`} tag={Link} className="mt-3">
                          <img
                            alt="..."
                            className="card-img-top w-100"
                            src={`data:${blog.image.contentType};base64,${arrayBufferToBase64(blog.image.data.data)}`}
                          />
                          <CardBody className="p-0 mt-2">
                            <div className="grey-badge">
                              <span className="grey-bg">
                                {blog.category.toUpperCase()}
                              </span>
                              <span className="date">
                                {getFormattedDate(new Date(blog.updatedAt))}
                              </span>
                            </div>
                            <h5 className="card-title news-title">
                              {blog.title}
                            </h5>
                            <p className="card-text news-text">
                              {blog.description}
                            </p>
                          </CardBody>
                        </Card>
                      </Col>
                    )
                  })) : (<></>)
                }
              </Row>
              <Row className="mt-3">
                <Col className="text-center">
                  <Link to="/blog">
                    <Button color="primary" className="contact-us-btn more-btn py-2 px-4">
                      READ MORE
                    </Button>
                  </Link>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </section>
    </>
  )
}

export default Home;