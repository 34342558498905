import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

const INITIAL_STATE = {
  showModal: false,
  uploadImage: () => { },
  cancelUpload: () => { },
  imgRef: null,
  imgSrc: null,
  croppedImgData: null,
  imgCrop: {
    unit: 'px', width: 240, height: 170
  }
};

class ImgUploadModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: props.showModal || false,
      uploadImage: props.uploadImage,
      cancelUpload: props.cancelUpload,
      imgRef: props.imgRef,
      imgSrc: props.imgSrc,
      croppedImgData: props.croppedImgData,
      imgCrop: props.imgCrop || {
        unit: 'px', width: 240, height: 170
      }
    };
    this.croppedImgData = '';
    this.closeModal = this.closeModal.bind(this);
  }

  closeModal = () => {
    this.setState({
      ...INITIAL_STATE
    });
    this.state.cancelUpload();
  }

  sendCroppedImg = () => {
    this.setState({
      ...INITIAL_STATE
    });
    this.state.uploadImage(this.croppedImgData);
  }

  onImageLoaded = (image) => {
    this.setState({ imgRef: image });
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    this.setState({ imgCrop: crop });
  };

  makeClientCrop = async (crop) => {
    if (this.state.imgRef && crop.width && crop.height) {
      this.croppedImgData = await this.getCroppedImg(this.state.imgRef, crop, 'croppedImg.jpeg');
      // this.setState({ croppedImgData: croppedImgData });
    }
  }

  getCroppedImg = (img, crop, fileName = 'croppedImg.jpeg') => {
    const canvas = document.createElement('canvas');
    const scaleX = img.naturalWidth / img.width;
    const scaleY = img.naturalHeight / img.height;
    canvas.width = this.state.imgCrop.width;
    canvas.height = this.state.imgCrop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      img, crop.x * scaleX, crop.y * scaleY,
      crop.width * scaleX, crop.height * scaleY,
      0, 0,
      crop.width, crop.height
    );

    const base64Img = canvas.toDataURL('image/jpeg');
    const imageData = {
      'data': base64Img.split('base64,')[1],
      'contentType': 'image/jpeg'
    }
    return imageData;
  }

  render() {
    const { showModal, imgSrc, imgCrop } = this.state;
    return (
      <Modal
        isOpen={showModal}
        toggle={this.closeModal}
        onClosed={this.closeModal}
        className="ImgUploadModal"
        id="ImgUploadModal"
        backdrop="static"
        size="lg"
      >
        <ModalHeader toggle={this.closeModal}>
          <p className="title mb-0">Edit Photo</p>
        </ModalHeader>
        <ModalBody className="text-center">
          {imgSrc && (
            <ReactCrop
              id="croppedImg"
              src={imgSrc}
              crop={imgCrop}
              minWidth={240}
              minHeight={170}
              maxWidth={240}
              maxHeight={170}
              // keepSelection={true}
              // disabled={true}
              locked={true}
              ruleOfThirds={true}
              circularCrop={true}
              onImageLoaded={this.onImageLoaded}
              onComplete={this.onCropComplete}
              onChange={this.onCropChange}
            />
          )}
        </ModalBody>
        <ModalFooter>
          <div className="text-right mt-2">
            <Button
              color="default"
              className="btn btn-theme btn-white mx-1"
              onClick={this.closeModal}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              className="btn btn-theme btn-white mx-1"
              onClick={this.sendCroppedImg}
            >
              Save Photo
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

export default withRouter(ImgUploadModal);
