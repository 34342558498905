const axios = require('axios');
const API_SERVER_URL = process.env.REACT_APP_API_URL;

class WikiService {
  async getWikiCategories() {
    try {
      const config = {
        method: 'GET',
        url: `${API_SERVER_URL}/wikis/getCategories`,
      };
      let response = await axios(config);
      if (response.status === 200 && response.data.status === 'success') {
        return response.data.data;
      } else {
        throw new Error(response.data.message || 'Error while fetching wiki categories');
      }
    } catch (error) {
      let errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || 'Error while fetching wiki categories';
      throw new Error(errorMessage);
    }
  }

  async getAllWikis() {
    try {
      const config = {
        method: 'GET',
        url: `${API_SERVER_URL}/wikis/getAll?sort=createdAt:desc`,
      };
      let response = await axios(config);
      if (response.status === 200 && response.data.status === 'success') {
        return response.data.data;
      } else {
        throw new Error(response.data.message || 'Error while fetching wikis');
      }
    } catch (error) {
      let errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || 'Error while fetching wikis';
      throw new Error(errorMessage);
    }
  }

  async getLatestWikis({ sort = "createdAt:desc", offset = 0, limit = 3 }) {
    try {
      let url = `${API_SERVER_URL}/wikis/getAll`;
      if (offset) {
        url += `?offset=${offset}`;
      }
      if (limit) {
        url += `?limit=${limit}`;
      }
      if (sort) {
        url += `?sort=${sort}`;
      }

      const config = {
        method: 'GET',
        url: url,
      };
      let response = await axios(config);
      if (response.status === 200 && response.data.status === 'success') {
        return response.data.data;
      } else {
        throw new Error(response.data.message || 'Error while fetching latest wikis');
      }
    } catch (error) {
      let errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || 'Error while fetching latest wikis';
      throw new Error(errorMessage);
    }
  }
}

const wikiService = new WikiService();
export {
  wikiService
};