const axios = require('axios');
const API_SERVER_URL = process.env.REACT_APP_API_URL;

class UserService {
  async createUser(data) {
    try {
      const config = {
        method: 'POST',
        url: `${API_SERVER_URL}/users/create`,
        headers: {
          contentType: 'application/json'
        },
        data: data
      };
      let response = await axios(config);
      if (response.status === 200 && response.data.status === 'success') {
        return true;
      } else {
        throw new Error(response.data.message || 'Error while creating a user');
      }
    } catch (error) {
      let errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || 'Error while creating a user';
      throw new Error(errorMessage);
    }
  }

  async getDashboardData(data) {
    try {
      const config = {
        method: 'POST',
        url: `${API_SERVER_URL}/users/dashboard`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };
      let response = await axios(config);
      if (response.status === 200 && response.data.status === 'success') {
        return response.data.data;
      } else {
        throw new Error(response.data.message || 'Error while fetching user dashboard data');
      }
    } catch (error) {
      let errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || 'Error while fetching user dashboard data';
      throw new Error(errorMessage);
    }
  }

  async getProfileData(data) {
    try {
      const config = {
        method: 'POST',
        url: `${API_SERVER_URL}/users/profile`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };
      let response = await axios(config);
      if (response.status === 200 && response.data.status === 'success') {
        return response.data.data;
      } else {
        throw new Error(response.data.message || 'Error while fetching user profile data');
      }
    } catch (error) {
      let errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || 'Error while fetching user profile data';
      throw new Error(errorMessage);
    }
  }
}

const userService = new UserService();
export {
  userService
};