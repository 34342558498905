const axios = require('axios');
const API_SERVER_URL = process.env.REACT_APP_API_URL;

class BlogService {
  async getBlogCategories() {
    try {
      const config = {
        method: 'GET',
        url: `${API_SERVER_URL}/blogs/getCategories`,
      };
      let response = await axios(config);
      if (response.status === 200 && response.data.status === 'success') {
        return response.data.data;
      } else {
        throw new Error(response.data.message || 'Error while fetching blog categories');
      }
    } catch (error) {
      let errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || 'Error while fetching blog categories';
      throw new Error(errorMessage);
    }
  }

  async getAllBlogs() {
    try {
      const config = {
        method: 'GET',
        url: `${API_SERVER_URL}/blogs/getAll?sort=createdAt:desc`,
      };
      let response = await axios(config);
      if (response.status === 200 && response.data.status === 'success') {
        return response.data.data;
      } else {
        throw new Error(response.data.message || 'Error while fetching blogs');
      }
    } catch (error) {
      let errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || 'Error while fetching blogs';
      throw new Error(errorMessage);
    }
  }

  // /blogs/getAll?sort=price:asc,name:desc&offset=0&limit=10
  async getLatestBlogs({ sort = "createdAt:desc", offset = 0, limit = 3 }) {
    try {
      let url = `${API_SERVER_URL}/blogs/getAll`;
      if (offset) {
        url += `?offset=${offset}`;
      }
      if (limit) {
        url += `?limit=${limit}`;
      }
      if (sort) {
        url += `?sort=${sort}`;
      }

      const config = {
        method: 'GET',
        url: url,
      };
      let response = await axios(config);
      if (response.status === 200 && response.data.status === 'success') {
        return response.data.data;
      } else {
        throw new Error(response.data.message || 'Error while fetching latest blogs');
      }
    } catch (error) {
      let errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || 'Error while fetching latest blogs';
      throw new Error(errorMessage);
    }
  }

  async getBlogByProperty(data) {
    try {
      const config = {
        method: 'POST',
        url: `${API_SERVER_URL}/blogs/getByProperty`,
        data: data,
        headers: {
          contentType: 'application/json'
        }
      };
      let response = await axios(config);
      if (response.status === 200 && response.data.status === 'success') {
        return response.data.data;
      } else {
        throw new Error(response.data.message || 'Error while fetching blog info');
      }
    } catch (error) {
      let errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || 'Error while fetching blog info';
      throw new Error(errorMessage);
    }
  }
}

const blogService = new BlogService();
export {
  blogService
};