import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { FirebaseContext } from "../contexts";
import { Container, Row, Col, Button } from "reactstrap";
// Core Components
import Header from "../components/Layout/Header";

const Success = () => {
  const { setLoading } = useContext(FirebaseContext);

  useEffect(() => {
    document.body.classList.add("white-bg");
    setLoading(false);
  });

  useEffect(() => {
    return () => {
      document.body.classList.remove("white-bg");
      setLoading(true);
    };
  }, [setLoading]);

  return (
    <>
      <div className="header-nav-bg">
        <Header />
        <Container className="text-center mt-5">
          <Row>
            <Col>
              <i className="fas fa-check-circle fa-5x"></i>
              <h1 className="mt-5">Your payment was successful!</h1>
              <h4 className="mt-4">Thank you!</h4>
              <Link to={`/`}>
                <Button className="mt-4 contact-us-btn more-btn py-2 px-4">
                  Go To Home
                </Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Success;