import React, { useContext, useEffect, useCallback, useState } from "react";
import { FirebaseContext } from "../contexts";
import {
  Container,
  Row,
  Col,
  Navbar,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
} from "reactstrap";
// Services
import { userService, toastAlert } from "../Services";
// Core Components
import Header from "../components/Layout/Header";
import SidebarMenu from "../components/dashboard/SidebarMenu";
import PaymentCard from "../components/dashboard/PaymentCard";

const Profile = () => {
  const { user, firebase, setLoading } = useContext(FirebaseContext);
  const [profileData, setProfileData] = useState(null);
  const [formValues, setFormValues] = useState({
    password: "",
    newPassword: "",
    confirmNewPassword: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [sidebarMenuOpen, setSidebarMenuOpen] = useState(false);
  const toggleSidebarMenu = () => setSidebarMenuOpen(!sidebarMenuOpen);

  const getProfileData = useCallback(async () => {
    try {
      let idToken = await firebase.auth.currentUser.getIdToken(true);
      let profileData = await userService.getProfileData({ idToken });
      setProfileData(profileData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toastAlert("error", error.message || "Error while fetching user profile data!");
    }
  }, [firebase, setLoading]);

  const togglePassword = (event) => {
    event.preventDefault();
    let inputElement = event.currentTarget.parentElement.childNodes[1];
    let eyeElement = event.currentTarget.firstChild;
    let inputType = inputElement.getAttribute("type");
    if (inputType === "text") {
      inputElement.setAttribute("type", "password");
      eyeElement.classList.remove("fa-eye");
      eyeElement.classList.add("fa-eye-slash");
    } else {
      inputElement.setAttribute("type", "text");
      eyeElement.classList.remove("fa-eye-slash");
      eyeElement.classList.add("fa-eye");
    }
  };

  const handleChange = (event) => {
    // event.persist();
    event.preventDefault();
    let { name, value } = event.target;
    value = value.trim();

    let errors = { ...formErrors };
    switch (name) {
      case "password":
        errors[name] = value === "" ? "Password is required!" : "";
        break;
      case "newPassword":
        errors[name] =
          value.length < 6 ? "New Password must be 6 characters long!" : "";
        errors["confirmNewPassword"] =
          value && value !== formValues.confirmPassword
            ? "New Password and Confirm New Password does not match!"
            : "";
        break;
      case "confirmNewPassword":
        errors[name] =
          value !== formValues.newPassword
            ? "New Password and Confirm New Password does not match!"
            : "";
        break;
      default:
        break;
    }
    setFormValues((values) => ({ ...values, [name]: value }));
    setFormErrors((_) => ({ ...errors }));
  };

  const validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
    return valid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validateForm(formErrors)) {
      try {
        let fUser = await firebase.doSignInWithEmailAndPassword(
          user.email,
          formValues.password
        );
        await fUser.user.updatePassword(formValues.newPassword);
        setFormValues({
          password: "",
          newPassword: "",
          confirmNewPassword: "",
        });
        toastAlert("success", "Password updated successfully!");
      } catch (error) {
        toastAlert("error", error.message);
      }
    }
  };

  useEffect(() => {
    document.body.classList.add("white-bg", "dashboard");
    getProfileData();
  }, [getProfileData]);

  useEffect(() => {
    return () => {
      document.body.classList.remove("white-bg", "dashboard");
      setLoading(true);
    };
  }, [setLoading]);

  return (
    <>
      <Header />
      <section>
        <div className="dashboard">
          <div id="wrapper" className={sidebarMenuOpen ? "toggled" : ""}>
            <SidebarMenu toggleSidebarMenu={toggleSidebarMenu} />
            <div id="page-content-wrapper">
              <Container>
                <Navbar className="mt-2">
                  <Button
                    id="menu-toggle"
                    onClick={toggleSidebarMenu}
                    color="primary"
                    className="navbar-toggler ml-n3 border-0 bg-transparent text-dark shadow-none"
                  >
                    <i className="fas fa-bars text-dak"></i>
                  </Button>
                </Navbar>
                <div className="d-flex page-content-dashboard">
                  <div className="dashboard-subscribe-content w-100">
                    <Row className="m-0 p-0">
                      <Col sm="12" className="mb-4 dash-head">
                        <h2 className="d-inline-block mt-3">Settings</h2>
                        <h5 className="mt-4 mb-0">PASSWORD</h5>
                      </Col>
                      <Col lg="10" className="dashboard-form dsb-fm">
                        <Form onSubmit={handleSubmit} id="userProfileForm">
                          <Row className="form-row">
                            <Col md="6">
                              <FormGroup className="mb-3">
                                <Label for="password">Current Password</Label>
                                <Input
                                  type="password"
                                  name="password"
                                  value={formValues.password}
                                  onChange={handleChange}
                                  invalid={!!formErrors.password}
                                  required
                                />
                                <FormFeedback invalid={formErrors.password}>
                                  {formErrors.password}
                                </FormFeedback>
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row className="form-row">
                            <Col md="6" className="toggle-password">
                              <FormGroup className="mb-3">
                                <Label for="newPassword">New Password</Label>
                                <Input
                                  type="password"
                                  name="newPassword"
                                  className="pr-5"
                                  value={formValues.newPassword}
                                  onChange={handleChange}
                                  invalid={!!formErrors.newPassword}
                                  required
                                />
                                <Button
                                  type="button"
                                  className="border-0 p-0 bg-transparent text-dark shadow-none"
                                  onClick={togglePassword}
                                >
                                  <i className="fa fa-eye-slash"></i>
                                </Button>
                                <FormFeedback
                                  className="mt-n3"
                                  invalid={formErrors.newPassword}
                                >
                                  {formErrors.newPassword}
                                </FormFeedback>
                              </FormGroup>
                            </Col>

                            <Col md="6" className="toggle-password">
                              <FormGroup className="mb-3">
                                <Label for="confirmNewPassword">
                                  Confirm New Password
                                </Label>
                                <Input
                                  type="password"
                                  name="confirmNewPassword"
                                  className="pr-5"
                                  value={formValues.confirmNewPassword}
                                  onChange={handleChange}
                                  invalid={!!formErrors.confirmNewPassword}
                                  required
                                />
                                <Button
                                  type="button"
                                  className="border-0 p-0 bg-transparent text-dark shadow-none"
                                  onClick={togglePassword}
                                >
                                  <i className="fa fa-eye-slash"></i>
                                </Button>
                                <FormFeedback
                                  className="mt-n3"
                                  invalid={formErrors.confirmNewPassword}
                                >
                                  {formErrors.confirmNewPassword}
                                </FormFeedback>
                              </FormGroup>
                            </Col>

                            <Button
                              type="submit"
                              className="contact-us-btn more-btn py-2 px-5"
                            >
                              Save
                            </Button>
                          </Row>
                        </Form>
                      </Col>

                      {profileData && profileData.billing_portal_url ? (
                        <Col sm="12" className="payment-p mb-4 p-0">
                          <h5 className="mt-5 mb-0">Payment</h5>
                          <PaymentCard name="WALTER WHITE" number="5678" />
                          <div className="m-0 p-0">
                            <Button
                              className="contact-us-btn more-btn shadow-none py-2 px-5"
                              onClick={(e) => window.open(profileData.billing_portal_url, "_blank")}
                            >
                              Manage subscription
                            </Button>
                          </div>
                        </Col>
                      ) : (
                        <></>
                      )}
                    </Row>
                  </div>
                </div>
              </Container>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Profile;
