import React from 'react';

const PaymentCard = ({name, number}) => {
  return (
    <div className="payment-card p-0">
      <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="400" height="257" viewBox="13 80 440 252">
        <defs>
          <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#132057"/>
            <stop offset="1" stopColor="#2934af"/>
          </linearGradient>
          <filter id="Rectangle_338" x="0" y="56" width="421" height="301" filterUnits="userSpaceOnUse">
            <feOffset input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="15" result="blur"/>
            <feFlood floodColor="#2e49be" floodOpacity="0.502"/>
            <feComposite operator="in" in2="blur"/>
            <feComposite in="SourceGraphic"/>
          </filter>
          <clipPath id="clip-path">
            <ellipse id="Ellipse_63" data-name="Ellipse 63" cx="148.5" cy="132.5" rx="148.5" ry="132.5" transform="translate(1092 319)" fill="#2e49be"/>
          </clipPath>
          <linearGradient id="linear-gradient-2" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#2e49be"/>
            <stop offset="1" stopColor="#2934af"/>
          </linearGradient>  
        </defs>
        <g id="CB" transform="translate(-897 -319)">
          <g transform="matrix(1, 0, 0, 1, 897, 319)" filter="url(#Rectangle_338)">
            <rect id="Rectangle_338-2" data-name="Rectangle 338" width="331" height="211" rx="29" transform="translate(45 101)" fill="url(#linear-gradient)"/>
          </g>
          <text data-name="10€" transform="translate(971 580)" fill="#fff" fontSize="25" fontFamily="Montserrat-Bold, Montserrat" fontWeight="700"><tspan x="0" y="24">10€</tspan></text>
          <g id="Number" transform="translate(0 21)">
            {/* <text data-name="NUMBER" transform="translate(971 505)" fill="#fff" fontSize="12" fontFamily="Montserrat-Medium, Montserrat" fontWeight="500"><tspan x="0" y="12">NUMBER</tspan></text> */}
            <text data-name={`**** **** **** ${number}`} transform="translate(971 521)" fill="#fff" fontSize="14" fontFamily="Montserrat-Light, Montserrat" fontWeight="300"><tspan x="0" y="12">**** **** **** {number}</tspan></text>
          </g>
          <text data-name={name} transform="translate(971 447)" fill="#fff" fontSize="12" fontFamily="Montserrat-Medium, Montserrat" fontWeight="500"><tspan x="0" y="12">WALTER WHITE</tspan></text>
          <g id="Mask_Group_1" data-name="Mask Group 1" clipPath="url(#clip-path)">
            <rect id="Rectangle_339" data-name="Rectangle 339" width="331" height="211" rx="30" transform="translate(942 420)" fill="url(#linear-gradient-2)"/>
          </g>
          <g id="Group_234" data-name="Group 234" transform="translate(60.511 -12.891)">
            <circle id="Ellipse_64" data-name="Ellipse 64" cx="2" cy="2" r="2" transform="translate(1159.489 478.89)" fill="#fff"/>
            <path id="Path_158" data-name="Path 158" d="M15580.816,8030.237a8.812,8.812,0,0,1,0,10.131l4.105,2.341a13.214,13.214,0,0,0,2.018-7.405,12.784,12.784,0,0,0-2.018-7.326Z" transform="translate(-14415.508 -7554.375)" fill="#fff"/>
            <path id="Path_159" data-name="Path 159" d="M15597.286,8020.466l4.033-2.377s3.6,3.917,3.571,11.753-3.571,11.954-3.571,11.954l-4.033-2.366a19.475,19.475,0,0,0,2.9-9.588A18.243,18.243,0,0,0,15597.286,8020.466Z" transform="translate(-14424.459 -7549)" fill="#fff"/>
          </g>
          <g id="Visa" transform="translate(363.889 -27.29)">
            <path id="Path_160" data-name="Path 160" d="M1033.91,616.883l3.963,19.026c-.138.038-4.262.05-4.528.017-.013-.049-.029-.1-.041-.16q-.271-1.281-.542-2.562c-.032-.153-.034-.154-.187-.154h-5.986c-.159,0-.16,0-.214.157l-.881,2.552c-.019.056-.041.11-.064.173h-5.137c.028-.074.049-.137.075-.2l2.374-5.7,4.734-11.374a4.227,4.227,0,0,1,.519-.948,1.978,1.978,0,0,1,1.273-.791,2.521,2.521,0,0,1,.418-.043q2.055-.005,4.109,0C1033.828,616.875,1033.86,616.88,1033.91,616.883Zm-1.935,12.3c-.51-2.388-1.014-4.752-1.527-7.138-.03.037-.044.048-.049.062q-1.252,3.482-2.5,6.964a.59.59,0,0,0-.019.078s0,.01.006.015.006.008.014.018Z" transform="translate(-160.262 -1.131)" fill="#fff"/>
            <path id="Path_161" data-name="Path 161" d="M816.9,617.454l.1-.586c.066,0,.128-.006.191-.006h7.994a2.639,2.639,0,0,1,.967.163,2.17,2.17,0,0,1,1.408,1.72c.26,1.344.512,2.689.767,4.034l.79,4.161q.261,1.374.522,2.748a.158.158,0,0,0,.058.094l5.1-12.911h5.115l.009.017s.006.011,0,.015l-7.891,18.906c-.017.039-.037.077-.057.12h-5.158c-.017-.056-.037-.11-.052-.166q-1.59-6.135-3.179-12.27-.371-1.432-.746-2.863c-.035-.135-.082-.268-.127-.4a2.032,2.032,0,0,0-.9-1.108,7.27,7.27,0,0,0-1.109-.527,20.733,20.733,0,0,0-3.679-1.105C817,617.482,816.962,617.47,816.9,617.454Z" transform="translate(0 -1.119)" fill="#fff"/>
            <path id="Path_162" data-name="Path 162" d="M955.39,634.058l.883-4.138c.06.029.1.05.146.072a10.442,10.442,0,0,0,3.292,1.063,8.819,8.819,0,0,0,1.789.1,4.23,4.23,0,0,0,1.565-.363,2.3,2.3,0,0,0,.837-.621,1.387,1.387,0,0,0,.338-1.017,1.274,1.274,0,0,0-.215-.6,1.919,1.919,0,0,0-.471-.508,10.548,10.548,0,0,0-.894-.61c-.582-.339-1.179-.653-1.767-.982a11.167,11.167,0,0,1-1.734-1.156,4.921,4.921,0,0,1-1.379-1.731,4.325,4.325,0,0,1-.362-2.049,5.614,5.614,0,0,1,2.47-4.444,8.5,8.5,0,0,1,2.891-1.292,11.622,11.622,0,0,1,3.4-.32,13.276,13.276,0,0,1,3.86.736c.061.022.121.045.181.069a.539.539,0,0,1,.059.035l-.864,4c-.05-.02-.084-.033-.117-.047a9.987,9.987,0,0,0-3.323-.833,5.6,5.6,0,0,0-2,.172,2.72,2.72,0,0,0-.841.4,1.616,1.616,0,0,0-.444.466,1.062,1.062,0,0,0,0,1.19,1.7,1.7,0,0,0,.436.474,8.852,8.852,0,0,0,.8.544c.547.315,1.106.606,1.659.909a12.022,12.022,0,0,1,1.895,1.234,5.221,5.221,0,0,1,1.479,1.836,4.431,4.431,0,0,1,.4,1.736,5.855,5.855,0,0,1-2.925,5.379,8.96,8.96,0,0,1-2.851,1.1,13.032,13.032,0,0,1-3.6.233,15.423,15.423,0,0,1-4.334-.914c-.071-.026-.14-.056-.21-.085A.541.541,0,0,1,955.39,634.058Z" transform="translate(-109.12)" fill="#fff"/>
            <path id="Path_163" data-name="Path 163" d="M925.331,616.9,921.3,635.944c-.209.035-4.779.028-4.911-.008l4.042-19.035Z" transform="translate(-78.391 -1.151)" fill="#fff"/>
          </g>
        </g>
      </svg>
    </div>
  )
}

export default PaymentCard;