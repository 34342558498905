import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
  withRouter,
} from "react-router-dom";
import { ToastContainer, Slide } from "react-toastify";

// Core Components
import Footer from "./components/Layout/Footer";
import { PrivateRoute } from "./components/PrivateRoute";

// Page Components
import Home from "./views/Home";
import Wiki from "./views/Wiki";
import Hire from "./views/Hire";
import HireSearch from "views/HireSearch";
import HireProfileView from "views/HireProfileView";
import Blog from "./views/Blog";
import BlogArticle from "./views/BlogArticle";
import ContactUs from "./views/ContactUs";
import Login from "./views/Login";
import Signup from "./views/Signup";
import ForgotPassword from "./views/ForgotPassword";
import Dashboard from "./views/Dashboard";
import Message from "./views/Message";
import Profile from "./views/Profile";
import Success from "views/Success.js";
import Error from "views/Error.js";
import Error404 from "views/Error404.js";

const Main = withRouter(({ location }) => {
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Slide}
      />
      <main>
        <Switch>
          <Route exact path="/" render={(props) => <Home {...props} />} />
          <Route path="/wiki" render={(props) => <Wiki {...props} />} />
          <Route exact path="/hire" render={(props) => <Hire {...props} />} />
          <Route
            path="/hire/:uid"
            render={(props) => <HireProfileView {...props} />}
          />
          <Route
            path="/hire-search"
            render={(props) => <HireSearch {...props} />}
          />
          <Route exact path="/blog" render={(props) => <Blog {...props} />} />
          <Route
            path="/blog/:blogUrl"
            render={(props) => <BlogArticle {...props} />}
          />
          <Route
            path="/contact-us"
            render={(props) => <ContactUs {...props} />}
          />
          <Route path="/login" render={(props) => <Login {...props} />} />
          <Route path="/sign-up" render={(props) => <Signup {...props} />} />
          <Route
            path="/forgot-password"
            render={(props) => <ForgotPassword {...props} />}
          />
          <PrivateRoute
            path="/dashboard"
            redirectTo="/login"
            component={Dashboard}
          />
          <PrivateRoute
            path="/message"
            redirectTo="/login"
            component={Message}
          />
          <PrivateRoute
            path="/profile"
            redirectTo="/login"
            component={Profile}
          />
          <Route path="/success" render={(props) => <Success {...props} />} />
          <Route path="/error" render={(props) => <Error {...props} />} />
          <Route path="/404" render={(props) => <Error404 {...props} />} />
          <Redirect from="*" to="/404" />
        </Switch>
      </main>
      {location.pathname !== "/dashboard" &&
        location.pathname !== "/message" &&
        location.pathname !== "/profile" &&
        location.pathname !== "/hire-search" &&
        location.pathname !== "/login" &&
        location.pathname !== "/sign-up" &&
        location.pathname !== "/forgot-password" &&
        location.pathname !== "/success" &&
        location.pathname !== "/error" &&
        location.pathname !== "/404" && <Footer />}
    </>
  );
});

const App = () => {
  return (
    <Router basename="/">
      <Main />
    </Router>
  );
};

export default App;
