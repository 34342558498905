import React, { useContext, useEffect, useCallback, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FirebaseContext } from '../contexts';
import {
  Container,
  Row,
  Col,
  Card,
  Button
} from 'reactstrap';
import Select from 'react-select';
import FreelancerCard from '../components/common/FreelancerCard';
import {
  devProfileList,
  countryList
} from '../Services/FreelancerHelper';
import {
  freelancerService,
  toastAlert
} from '../Services';
// Core Components
import Header from '../components/Layout/Header';


const Hire = () => {
  const history = useHistory();
  const { setLoading } = useContext(FirebaseContext);
  const [freelancers, setFreelancers] = useState(null);
  const [profile, setProfile] = useState('');
  const [location, setLocation] = useState('');

  const showHireSearch = () => {
    let hireSearchPath = '/hire-search';
    if (profile) {
      hireSearchPath += `?profile=${profile}`
    }
    if (location) {
      hireSearchPath += profile ? `&location=${location}` : `?location=${location}`
    }
    history.push(hireSearchPath);
  }

  const getPageData = useCallback(async () => {
    try {
      let freelancers = await freelancerService.getLatestFreelancers('', 0, 3);
      setFreelancers(freelancers);
    } catch (error) {
      setFreelancers([]);
      toastAlert('error', 'Error while fetching freelancers');
    }
    setLoading(false);
  }, [setLoading]);

  useEffect(() => {
    getPageData();
  }, [getPageData]);

  useEffect(() => {
    return () => {
      setLoading(true);
    }
  }, [setLoading]);

  return (
    <>
      <div className="header-nav-bg">
        <Header />
        <section>
          <div className="hero-section">
            <div className="text-center wow fadeInUp arrow-hero">
              <h1 className="hero-title ">Find your Freelancer</h1>
              <h5 className="hero-t">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam</h5>
              <div className="serach-section">
                <div className="search-inner">
                  <div className="first-part text-left">
                    <i className="fas fa-search"></i>
                    <Select
                      name="profiles"
                      id="profiles"
                      className="react-select react-select-primary search-input w-100 p-0 border-0 shadow-none"
                      classNamePrefix="hire-react-select"
                      defaultValue={{ label: "Select Profession (e.g. Data Scientist)", value: "" }}
                      options={devProfileList}
                      onChange={(e) => setProfile(e.value)}
                    />
                  </div>
                  <div className="border-part"></div>
                  <div className="second-part">
                    <i className="fas fa-map-marker-alt"></i>
                    <Select
                      name="location"
                      id="location"
                      className="react-select react-select-primary search-input w-100 p-0 border-0 shadow-none"
                      classNamePrefix="hire-react-select"
                      defaultValue={{ label: "Select Country...", value: "" }}
                      options={countryList}
                      onChange={(e) => setLocation(e.value)}
                    />
                  </div>
                  <div className="third-part">
                    <Button color="primary" className="search-a p-0 border-0 bg-transparent shadow-none" onClick={showHireSearch}>
                      <div className="search-btn">
                        <i className="fas fa-search"></i>
                      </div>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section>
        <Container className="skill-section wow fadeInUp">
          <h4 className="skill-m-title">Metiers Available</h4>
          <div className="card-columns">
            <Card to="/hire-search?profile=Graphics Designer" tag={Link}>
              <img
                alt="..."
                className="card-img-top"
                src={require(`../assets/images/pexels-ovan-57690.jpg`).default}
              />
              <div className="card-img-overlay layer">
                <h5 className="card-title">Graphic Designer</h5>
              </div>
            </Card>
            <Card to="/hire-search?profile=Data Scientist" tag={Link}>
              <img
                alt="..."
                className="card-img-top"
                src={require(`../assets/images/pexels-markus-spiske-1936299.jpg`).default}
              />
              <div className="card-img-overlay layer">
                <h5 className="card-title">Data Scientist</h5>
              </div>
            </Card>
            <Card to="/hire-search?profile=Fullstack Developer" tag={Link}>
              <img
                alt="..."
                className="card-img-top"
                src={require(`../assets/images/pexels-luis-gomes-546819.jpg`).default}
              />
              <div className="card-img-overlay layer">
                <h5 className="card-title">Fullstack Developer</h5>
              </div>
            </Card>
            <Card to="/hire-search?profile=UX Designer" tag={Link}>
              <img
                alt="..."
                className="card-img-top"
                src={require(`../assets/images/pexels-pixabay-273230.jpg`).default}
              />
              <div className="card-img-overlay layer">
                <h5 className="card-title">UX Designer</h5>
              </div>
            </Card>
            <Card to="/hire-search?profile=Mobile Developer" tag={Link}>
              <img
                alt="..."
                className="card-img-top"
                src={require(`../assets/images/pexels-christina-morillo-1181244.jpg`).default}
              />
              <div className="card-img-overlay layer">
                <h5 className="card-title">Mobile Developer</h5>
              </div>
            </Card>
          </div>
        </Container>
      </section>

      <section>
        <Container className="portfolio-section wow fadeInUp">
          <h4 className="skill-m-title">Last profile add</h4>
          <Row>
            {freelancers && freelancers.length ? (
              freelancers.map((freelancer, i) => {
                return (
                  <Col md="6" sm="3" lg="4" className="mt-3" key={i}>
                    <FreelancerCard freelancer={freelancer} />
                  </Col>
                )
              })
            ) : (
              <></>
            )}
          </Row>
        </Container>
      </section>

      <section>
        <div className="contact-us">
          <Container>
            <Row>
              <Col lg="4" className="d-flex align-items-center cbox">
                <h2 className="freelancer-text">You're a <br /> Freelancer ?</h2>
              </Col>
              <Col lg="4" className="contact-us-now text-center d-flex align-items-center cbox">
                <div>
                  <h4 className="contact-text">CONTACT US NOW</h4>
                  <p>to add your profile on the website and <br /> be contacted by company</p>
                </div>
              </Col>
              <Col lg="4" className="text-right d-flex align-items-center justify-content-lg-end cbox">
                <div>
                  <h2 className="price-font">10€<span className="small-font">/ month</span></h2>
                  <Link to="/contact-us">
                    <span className="contact-us-btn">Contact us now</span>
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </>
  )
}

export default Hire;