// https://codepen.io/johndavemanuel/pen/oOGqga

const languageList = [
  {
    value: "Abkhazian",
    label: "Abkhazian"
  },
  {
    value: "Afar",
    label: "Afar"
  },
  {
    value: "Afrikanns",
    label: "Afrikanns"
  },
  {
    value: "Albanian",
    label: "Albanian"
  },
  {
    value: "Amharic",
    label: "Amharic"
  },
  {
    value: "Arabic",
    label: "Arabic"
  },
  {
    value: "Armenian",
    label: "Armenian"
  },
  {
    value: "Assamese",
    label: "Assamese"
  },
  {
    value: "Aymara",
    label: "Aymara"
  },
  {
    value: "Azerbaijani",
    label: "Azerbaijani"
  },
  {
    value: "Bashkir",
    label: "Bashkir"
  },
  {
    value: "Basque",
    label: "Basque"
  },
  {
    value: "Bengali, Bangla",
    label: "Bengali, Bangla"
  },
  {
    value: "Bhutani",
    label: "Bhutani"
  },
  {
    value: "Bihari",
    label: "Bihari"
  },
  {
    value: "Bislama",
    label: "Bislama"
  },
  {
    value: "Breton",
    label: "Breton"
  },
  {
    value: "Bulgarian",
    label: "Bulgarian"
  },
  {
    value: "Burmese",
    label: "Burmese"
  },
  {
    value: "Byelorussian",
    label: "Byelorussian"
  },
  {
    value: "Cambodian",
    label: "Cambodian"
  },
  {
    value: "Catalan",
    label: "Catalan"
  },
  {
    value: "Chinese (Mandarin)",
    label: "Chinese (Mandarin)"
  },
  {
    value: "Corsican",
    label: "Corsican"
  },
  {
    value: "Croation",
    label: "Croation"
  },
  {
    value: "Czech",
    label: "Czech"
  },
  {
    value: "Danish",
    label: "Danish"
  },
  {
    value: "Dutch",
    label: "Dutch"
  },
  {
    value: "English",
    label: "English"
  },
  {
    value: "Esperanto",
    label: "Esperanto"
  },
  {
    value: "Estonian",
    label: "Estonian"
  },
  {
    value: "Faeroese",
    label: "Faeroese"
  },
  {
    value: "Fiji",
    label: "Fiji"
  },
  {
    value: "Finnish",
    label: "Finnish"
  },
  {
    value: "French",
    label: "French"
  },
  {
    value: "Frisian",
    label: "Frisian"
  },
  {
    value: "Gaelic (Scots Gaelic)",
    label: "Gaelic (Scots Gaelic)"
  },
  {
    value: "Galician",
    label: "Galician"
  },
  {
    value: "Georgian",
    label: "Georgian"
  },
  {
    value: "German",
    label: "German"
  },
  {
    value: "Greek",
    label: "Greek"
  },
  {
    value: "Greenlandic",
    label: "Greenlandic"
  },
  {
    value: "Guarani",
    label: "Guarani"
  },
  {
    value: "Gujarati",
    label: "Gujarati"
  },
  {
    value: "Hausa",
    label: "Hausa"
  },
  {
    value: "Hebrew",
    label: "Hebrew"
  },
  {
    value: "Hindi",
    label: "Hindi"
  },
  {
    value: "Hungarian",
    label: "Hungarian"
  },
  {
    value: "Icelandic",
    label: "Icelandic"
  },
  {
    value: "Indonesian",
    label: "Indonesian"
  },
  {
    value: "Interlingua",
    label: "Interlingua"
  },
  {
    value: "Interlingue",
    label: "Interlingue"
  },
  {
    value: "Inupiak",
    label: "Inupiak"
  }, 
  {
    value: "Irish",
    label: "Irish"
  },
  {
    value: "Italian",
    label: "Italian"
  },
  {
    value: "Japanese",
    label: "Japanese"
  },
  {
    value: "Javanese",
    label: "Javanese"
  },
  {
    value: "Kannada",
    label: "Kannada"
  },
  {
    value: "Kashmiri",
    label: "Kashmiri"
  },
  {
    value: "Kazakh",
    label: "Kazakh"
  },
  {
    value: "Kinyarwanda",
    label: "Kinyarwanda"
  },
  {
    value: "Kirghiz",
    label: "Kirghiz"
  },
  {
    value: "Kirundi",
    label: "Kirundi"
  },
  {
    value: "Korean",
    label: "Korean"
  },
  {
    value: "Kurdish",
    label: "Kurdish"
  },
  {
    value: "Laothian",
    label: "Laothian"
  },
  {
    value: "Latin",
    label: "Latin"
  },
  {
    value: "Latvian, Lettish",
    label: "Latvian, Lettish"
  },
  {
    value: "Lingala",
    label: "Lingala"
  },
  {
    value: "Lithuanian",
    label: "Lithuanian"
  },
  {
    value: "Macedonian",
    label: "Macedonian"
  },
  {
    value: "Malagasy",
    label: "Malagasy"
  },
  {
    value: "Malay",
    label: "Malay"
  },
  {
    value: "Malayalam",
    label: "Malayalam"
  },
  {
    value: "Maltese",
    label: "Maltese"
  },
  {
    value: "Maori",
    label: "Maori"
  },
  {
    value: "Marathi",
    label: "Marathi"
  },
  {
    value: "Moldavian",
    label: "Moldavian"
  },
  {
    value: "Mongolian",
    label: "Mongolian"
  },
  {
    value: "Nauru",
    label: "Nauru"
  },
  {
    value: "Nepali",
    label: "Nepali"
  },
  {
    value: "Norwegian",
    label: "Norwegian"
  },
  {
    value: "Occitan",
    label: "Occitan"
  },
  {
    value: "Oriya",
    label: "Oriya"
  },
  {
    value: "Oromo, Afan",
    label: "Oromo, Afan"
  },
  {
    value: "Pashto, Pushto",
    label: "Pashto, Pushto"
  },
  {
    value: "Persian",
    label: "Persian"
  },
  {
    value: "Polish",
    label: "Polish"
  },
  {
    value: "Portuguese",
    label: "Portuguese"
  },
  {
    value: "Punjabi",
    label: "Punjabi"
  },
  {
    value: "Quechua",
    label: "Quechua"
  },
  {
    value: "Rhaeto-Romance",
    label: "Rhaeto-Romance"
  },
  {
    value: "Romanian",
    label: "Romanian"
  },
  {
    value: "Russian",
    label: "Russian"
  },
  {
    value: "Samoan",
    label: "Samoan"
  },
  {
    value: "Sangro",
    label: "Sangro"
  },
  {
    value: "Sanskrit",
    label: "Sanskrit"
  },
  {
    value: "Serbian",
    label: "Serbian"
  },
  {
    value: "Serbo-Croatian",
    label: "Serbo-Croatian"
  },
  {
    value: "Sesotho",
    label: "Sesotho"
  },
  {
    value: "Setswana",
    label: "Setswana"
  },
  {
    value: "Shona",
    label: "Shona"
  },
  {
    value: "Sindhi",
    label: "Sindhi"
  },
  {
    value: "Singhalese",
    label: "Singhalese"
  },
  {
    value: "Siswati",
    label: "Siswati"
  },
  {
    value: "Slovak",
    label: "Slovak"
  },
  {
    value: "Slovenian",
    label: "Slovenian"
  },
  {
    value: "Somali",
    label: "Somali"
  },
  {
    value: "Spanish",
    label: "Spanish"
  },
  {
    value: "Sudanese",
    label: "Sudanese"
  },
  {
    value: "Swahili",
    label: "Swahili"
  },
  {
    value: "Swedish",
    label: "Swedish"
  },
  {
    value: "Tagalog",
    label: "Tagalog"
  },
  {
    value: "Tajik",
    label: "Tajik"
  },
  {
    value: "Tamil",
    label: "Tamil"
  },
  {
    value: "Tatar",
    label: "Tatar"
  },
  {
    value: "Telugu",
    label: "Telugu"
  },
  {
    value: "Thai",
    label: "Thai"
  },
  {
    value: "Tibetan",
    label: "Tibetan"
  },
  {
    value: "Tigrinya",
    label: "Tigrinya"
  },
  {
    value: "Tonga",
    label: "Tonga"
  },
  {
    value: "Tsonga",
    label: "Tsonga"
  },
  {
    value: "Turkish",
    label: "Turkish"
  },
  {
    value: "Turkmen",
    label: "Turkmen"
  },
  {
    value: "Twi",
    label: "Twi"
  },
  {
    value: "Ukranian",
    label: "Ukranian"
  },
  {
    value: "Urdu",
    label: "Urdu"
  },
  {
    value: "Uzbek",
    label: "Uzbek"
  },
  {
    value: "Vietnamese",
    label: "Vietnamese"
  },
  {
    value: "Volapuk",
    label: "Volapuk"
  },
  {
    value: "Welsh",
    label: "Welsh"
  },
  {
    value: "Wolof",
    label: "Wolof"
  },
  {
    value: "Xhosa",
    label: "Xhosa"
  },
  {
    value: "Yiddish",
    label: "Yiddish"
  },
  {
    value: "Yoruba",
    label: "Yoruba"
  },
  {
    value: "Zulu",
    label: "Zulu"
  }
];

const langProficiencyList = [
  {
    value: "Basic",
    label: "Basic"
    // label: "Basic - I write in this language decently",
  },
  {
    value: "Conversational",
    label: "Conversational"
    // label: "Conversational - I write and speak this language well"
  },
  {
    value: "Fluent",
    label: "Fluent"
    // label: "Fluent - I write and speak this language almost perfectly"
  },
  {
    value: "Native or Bilingual",
    label: "Native or Bilingual"
    // label: "Native or Bilingual - I write and speak this language perfectly, including colloquialisms"
  }
];

export {
  languageList,
  langProficiencyList
};