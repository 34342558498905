import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  Container,
  Row,
  Col
} from 'reactstrap';


const Footer = () => {
  const location = useLocation();
  const [hasmt150, sethasmt150] = useState(false);

  useEffect(() => {
    let pathname = location.pathname;
    if (['/', '/wiki', '/blog', '/contact-us'].indexOf(pathname) !== -1) {
      sethasmt150(true);
    } else {
      sethasmt150(false);
    }
  }, [location]);

  return (
    <footer>
      <Container fluid className={`footer-bg ${hasmt150 ? 'mt150' : ''}`}>
        <Container className="footer-inner">
          <Row>
            <Col md="3">
              <Link to="/contact-us">
                <span className="contact-us-footer-btn">Contact us</span>
              </Link>
            </Col>
            <Col md="3">
              <div>
                <h4>Service</h4>
                <p><Link to="/wiki">Wiki</Link></p>
                <p><Link to="/hire">Hire</Link></p>
                <p><Link to="/blog">Blog</Link></p>
                <p><Link to="/contact-us">Contact us</Link></p>
              </div>
            </Col>
            <Col md="3">
              <div>
                <h4>Company</h4>
                <p><Link to="/">Term of Service</Link></p>
                <p><Link to="/">Privacy Police</Link></p>
                <p><Link to="/contact-us">Contact</Link></p>
              </div>
            </Col>
            <Col md="3">
              <div>
                <div className="social-icon">
                  <Link to="#"><i className="fab fa-instagram"></i></Link>
                  <Link to="#"><i className="fab fa-twitter"></i></Link>
                  <Link to="#"><i className="fab fa-discord"></i></Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </footer>
  )
}

export default Footer;