import React, { useState, useContext, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FirebaseContext } from '../../contexts';
import {
  Button 
} from 'reactstrap';


const SidebarMenu = ({toggleSidebarMenu}) => {
  const { firebase } = useContext(FirebaseContext);
  const location = useLocation();
  const [pathname, setPathname] = useState('/');

  useEffect(() => {
    let pathname = location.pathname;
    setPathname(pathname);
  }, [location]);
  
  return (
    <div id="sidebar-wrapper">
      <Button className="closebtn p-0 m-0 border-0 shadow-none bg-transparent text-main" onClick={toggleSidebarMenu}>&times;</Button>
      <div className="sidebar-heading">
      </div>
      <div className="list-group list-group-flush py-3">
        <Link to="/dashboard" className={`list-group-item list-group-item-action ${pathname === "/dashboard" ? "active":""}`}>
          <svg xmlns="http://www.w3.org/2000/svg" width="31.405" height="38.752" viewBox="0 0 31.405 38.752">
            <g id="Icone_Doc" data-name="Icone Doc" transform="translate(-734.596 -731.809)">
              <rect id="Rectangle_285" data-name="Rectangle 285" width="6.456" height="2.123" rx="1.061"
                transform="translate(742.232 746.893)" />
              <rect id="Rectangle_286" data-name="Rectangle 286" width="16.133" height="2.123" rx="1.061"
                transform="translate(742.232 753.353)" />
              <rect id="Rectangle_287" data-name="Rectangle 287" width="16.133" height="2.123" rx="1.061"
                transform="translate(742.232 759.81)" />
              <path id="Path_76" data-name="Path 76"
                d="M765.992,746.168a1.054,1.054,0,0,0-.28-.729l-12.573-13.3a1.052,1.052,0,0,0-.771-.323v-.009h-8.314a9.468,9.468,0,0,0-9.457,9.458V761.1a9.468,9.468,0,0,0,9.457,9.457h12.492A9.468,9.468,0,0,0,766,761.1V746.168Zm-12.563-10.631,9.048,9.57h-3.729a5.325,5.325,0,0,1-5.319-5.319Zm3.116,32.9H744.053a7.342,7.342,0,0,1-7.334-7.334V741.267a7.343,7.343,0,0,1,7.334-7.335h7.253v5.857a7.45,7.45,0,0,0,7.441,7.441h5.132V761.1A7.342,7.342,0,0,1,756.545,768.439Z"
                transform="translate(0 0)" />
            </g>
          </svg>
          <span>Freelance</span>
        </Link>
        <Link to="/message" className={`list-group-item list-group-item-action ${pathname === "/message" ? "active":""}`}>
          <svg xmlns="http://www.w3.org/2000/svg" width="37.113" height="37.901" viewBox="0 0 37.113 37.901">
            <g id="Message_icone" data-name="Message icone" transform="translate(-614.249 -731.708)">
              <rect id="Rectangle_281" data-name="Rectangle 281" width="16.148" height="2.307" rx="1.153"
                transform="translate(621.134 738.638)" />
              <rect id="Rectangle_282" data-name="Rectangle 282" width="11.534" height="2.307" rx="1.153"
                transform="translate(621.134 743.252)" />
              <path id="Path_72" data-name="Path 72"
                d="M660.076,731.708h-21.5a4.2,4.2,0,0,0-4.191,4.191v23.437a1.036,1.036,0,0,0,1.689.8l6.881-5.595h17.12a4.2,4.2,0,0,0,4.191-4.191V735.9A4.2,4.2,0,0,0,660.076,731.708Zm2.119,18.646a2.122,2.122,0,0,1-2.119,2.119H642.552v0a1.031,1.031,0,0,0-.618.227l-5.477,4.454V735.9a2.122,2.122,0,0,1,2.12-2.119h21.5A2.122,2.122,0,0,1,662.2,735.9Z"
                transform="translate(-20.136)" />
              <path id="Path_73" data-name="Path 73"
                d="M672.077,745.083h-.943a1.036,1.036,0,0,0-1.036,1.036h0a1.036,1.036,0,0,0,1.036,1.036h.943a2.036,2.036,0,0,1,1.935,2.12v21.26l-5-4.454a.893.893,0,0,0-.564-.227v0h-16a2.036,2.036,0,0,1-1.935-2.12v-2.724a.945.945,0,0,0-.945-.945h0a.946.946,0,0,0-.946.945v2.724a4.026,4.026,0,0,0,3.827,4.191h15.628l6.283,5.595a.894.894,0,0,0,.6.232.883.883,0,0,0,.407-.1,1.049,1.049,0,0,0,.539-.936V749.274A4.026,4.026,0,0,0,672.077,745.083Z"
                transform="translate(-24.541 -4.138)" />
            </g>
          </svg>
          <span>Message</span>
        </Link>
        <Link to="/profile" className={`list-group-item list-group-item-action ${pathname === "/profile" ? "active":""}`}>
          <svg id="Profil_Icone" data-name="Profil Icone" xmlns="http://www.w3.org/2000/svg" width="32.011"
            height="38.344" viewBox="0 0 32.011 38.344">
            <path id="Path_70" data-name="Path 70"
              d="M577.6,765.212a15.942,15.942,0,0,0-3.5-1.605,22.131,22.131,0,0,1-4.828-2.277,1.152,1.152,0,1,0-1.28,1.916,23.912,23.912,0,0,0,5.31,2.522,14.2,14.2,0,0,1,2.991,1.346c.752.516.981,3.337.751,5.789a29.784,29.784,0,0,1-27.216,0c-.23-2.452,0-5.274.752-5.789a14.2,14.2,0,0,1,2.991-1.346,23.911,23.911,0,0,0,5.31-2.522,1.152,1.152,0,1,0-1.28-1.916,22.128,22.128,0,0,1-4.828,2.277,15.94,15.94,0,0,0-3.5,1.605c-2.424,1.663-1.817,7.428-1.671,8.567a1.153,1.153,0,0,0,.588.863,30.926,30.926,0,0,0,15.057,3.917c.063,0,.123,0,.186-.005s.123.005.186.005a30.926,30.926,0,0,0,15.057-3.917,1.153,1.153,0,0,0,.588-.863C579.414,772.639,580.021,766.874,577.6,765.212Z"
              transform="translate(-547.431 -740.214)" />
            <path id="Path_71" data-name="Path 71"
              d="M558.839,750.356c.443.686,2.814,4.041,6.721,4.042.064,0,.128,0,.192,0s.128,0,.192,0c3.907,0,6.277-3.356,6.721-4.042,1.333-2.066,1.365-4.066,1.4-6.185.042-2.746.091-5.855-2.205-8.108a9.751,9.751,0,0,0-12.212,0c-2.3,2.252-2.248,5.362-2.205,8.108C557.474,746.29,557.506,748.289,558.839,750.356Zm2.421-12.647a5.838,5.838,0,0,1,4.16-1.5c.1,0,.21,0,.316.006l.015,0,.015,0c.106,0,.211-.006.316-.006a5.838,5.838,0,0,1,4.16,1.5c1.591,1.559,1.554,3.923,1.515,6.427-.031,1.931-.055,3.458-1.03,4.969-.205.318-2.092,3.107-4.962,2.984h-.029c-2.869.123-4.757-2.666-4.962-2.984-.975-1.511-1-3.038-1.03-4.969C559.707,741.632,559.67,739.268,561.26,737.709Z"
              transform="translate(-549.746 -733.909)" />
          </svg>
          <span>Profile</span>
        </Link>
        <div>
          <Button onClick={firebase.doSignOut} className="logout-btn list-group-item list-group-item-action">
            <div className="logout menu-margin">
              <svg xmlns="http://www.w3.org/2000/svg" width="36.288" height="39.054" viewBox="0 0 36.288 39.054">
                <g id="Group_189" data-name="Group 189" transform="translate(-545.352 -815.707)">
                  <path id="Path_77" data-name="Path 77"
                    d="M577.363,815.707H566.232a1.19,1.19,0,1,0,0,2.379h11.131a5.978,5.978,0,0,1,5.971,5.971V846.41a5.978,5.978,0,0,1-5.971,5.972H566.232a1.19,1.19,0,1,0,0,2.379h11.131a8.36,8.36,0,0,0,8.351-8.351V824.058A8.36,8.36,0,0,0,577.363,815.707Z"
                    transform="translate(-4.074 0)" fill="#b41717" />
                  <path id="Path_78" data-name="Path 78"
                    d="M549.6,839.2h20.634a1.19,1.19,0,0,0,0-2.379H549.59l6.112-5.63a1.19,1.19,0,0,0-1.612-1.75l-8.354,7.7a1.19,1.19,0,0,0,0,1.752l8.354,7.664A1.19,1.19,0,0,0,555.7,844.8Z"
                    transform="translate(0 -2.776)" fill="#b41717" />
                </g>
              </svg>
              <span>Logout</span>
            </div>
          </Button>
        </div>
      </div>
    </div>
  )
}

export default SidebarMenu;