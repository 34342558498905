import React from 'react';
import { Link } from 'react-router-dom';
import {
  Card
} from 'reactstrap';


const FreelancerCard = ({ freelancer }) => {
  return (
    <Card to={`/hire/${freelancer.uid}`} tag={Link}>
      {freelancer.image && freelancer.image.data ?
        <img
          alt="..."
          className="card-img-top"
          src={`data:${freelancer.image.contentType};base64,${freelancer.image.data}`}
        /> : <img
          alt="..."
          className="card-img-top"
          src={require(`../../assets/images/p-img1.jpg`).default}
        />
      }
      <div className="card-img-overlay portfolio">
        <div className="person-details w-100">
          <h5 className="card-title">{freelancer.firstName}&nbsp;{freelancer.lastName}</h5>
          <h6 className="skill-description">{freelancer.profile}</h6>
          <div className="star-rate">
            {/* <svg xmlns="http://www.w3.org/2000/svg" width="145.396" height="21.485" viewBox="0 0 145.396 21.485">
              <g id="Stars" transform="translate(-169.913 -2429.388)">
                <path id="star"
                  d="M13.087,18.4l6.234,3.762-1.654-7.091L23.174,10.3l-7.253-.625L13.087,3,10.253,9.678,3,10.3l5.5,4.771L6.853,22.166Z"
                  transform="translate(166.913 2427.667)" fill="#fff"></path>
                <path id="star-2" data-name="star"
                  d="M13.087,18.4l6.234,3.762-1.654-7.091L23.174,10.3l-7.253-.625L13.087,3,10.253,9.678,3,10.3l5.5,4.771L6.853,22.166Z"
                  transform="translate(197.913 2427.667)" fill="#fff"></path>
                <path id="star-3" data-name="star"
                  d="M13.087,18.4l6.234,3.762-1.654-7.091L23.174,10.3l-7.253-.625L13.087,3,10.253,9.678,3,10.3l5.5,4.771L6.853,22.166Z"
                  transform="translate(228.913 2427.667)" fill="#fff"></path>
                <path id="star-4" data-name="star"
                  d="M13.087,18.4l6.234,3.762-1.654-7.091L23.174,10.3l-7.253-.625L13.087,3,10.253,9.678,3,10.3l5.5,4.771L6.853,22.166Z"
                  transform="translate(259.913 2427.667)" fill="#fff"></path>
                <path id="star-5" data-name="star"
                  d="M13.087,18.4l6.234,3.762-1.654-7.091L23.174,10.3l-7.253-.625L13.087,3,10.253,9.678,3,10.3l5.5,4.771L6.853,22.166Z"
                  transform="translate(290.913 2427.667)" fill="none" stroke="#fff" strokeWidth="1"></path>
              </g>
            </svg> */}
            <div className="rate">{freelancer.price}&nbsp;{freelancer.currency === 'usd' ? '$' : '€'}&nbsp;/&nbsp;hour</div>
          </div>
        </div>
      </div>
    </Card>
  )
}

export default FreelancerCard;