// import React from 'react';
import React from 'react';
import { FirebaseContext } from '../contexts';
import { io } from 'socket.io-client';
import {
  Container,
  Row,
  Col,
  Navbar,
  Button,
  Form,
  Input
} from 'reactstrap';
import {
  ChatList,
  MessageList
} from 'react-chat-elements';
import {
  chatService,
  toastAlert,
} from '../Services';
// Core Components
import Header from '../components/Layout/Header';
import SidebarMenu from '../components/dashboard/SidebarMenu';
import 'react-chat-elements/dist/main.css';
const APP_SOCKET_URL = process.env.REACT_APP_SOCKET_URL;


class Message extends React.Component {
  static contextType = FirebaseContext;

  constructor(props, context) {
    super(props, context);

    this.firebase = context.firebase;
    this.uid = context.user.uid;
    this.setLoading = context.setLoading;

    this.socket = React.createRef();
    this.scrollRef = React.createRef();

    this.state = {
      onlineUsers: [],
      chats: [],
      activeChat: null,
      findChatUser: '',
      chatUserList: [],
      filteredChatUserList: [],
      messageList: [],
      newMessage: '',
      sidebarMenuOpen: false
    };
    this.toggleSidebarMenu = this.toggleSidebarMenu.bind(this);
    this.setNewMessage = this.setNewMessage.bind(this);
    this.getMessageList = this.getMessageList.bind(this);
    this.getChatUserList = this.getChatUserList.bind(this);
    this.fliteredChatUserList = this.fliteredChatUserList.bind(this);
    this.updateActiveChat = this.updateActiveChat.bind(this);
    this.sendNewMessage = this.sendNewMessage.bind(this);
    this.initChats = this.initChats.bind(this);
  }

  toggleSidebarMenu() {
    this.setState(prevState => { return { ...prevState, sidebarMenuOpen: !this.state.sidebarMenuOpen } });
  }

  setNewMessage(newMessage) {
    this.setState(prevState => { return { ...prevState, newMessage } });
  };

  getMessageList(messageList) {
    messageList = messageList.map(msg => {
      let message = {
        'position': msg.from === this.uid ? 'right' : 'left',
        'type': 'text',
        'text': msg.message,
        'date': new Date(msg.timestamp)
      };
      return message;
    });
    messageList = messageList.sort((m1, m2) => m1.date.getTime() - m2.date.getTime());
    this.setState(prevState => { return { ...prevState, messageList } }, async () => {
      this.scrollRef.current && this.scrollRef.current.scrollIntoView({ behavior: 'smooth' });
    });
  }

  async getChatUserList(chats, uid, onlineUsers = this.state.onlineUsers) {
    let chatUserList = chats.map(c => {
      let userData;
      if (c.uid1 !== uid) {
        userData = c.user1;
      } else {
        userData = c.user2;
      }

      const avatar = userData.image && userData.image.data ? `data:${userData.image.contentType};base64,${userData.image.data}` : require(`../assets/images/no_user_img.png`).default
      const alt = 'Image';
      const title = userData.displayName || 'Unknown User';
      const lastMsg = c.messages.slice(-1)[0];
      const subtitle = (lastMsg && lastMsg['message']) || '';
      const date = (lastMsg && new Date(lastMsg['timestamp'])) || '';
      const unread = 0;
      const channel = c.channel;
      const isOnline = onlineUsers.find(uid => uid === userData.uid);
      const statusColor = isOnline ? 'lime' : 'transparent';

      const chat = { avatar, alt, title, subtitle, date, unread, channel, isOnline, statusColor };
      return chat;
    });
    return chatUserList;
  }

  async fliteredChatUserList(findChatUser) {
    const filteredChatUserList = this.state.chatUserList.filter((chat) => {
      if (chat.title.toLowerCase().includes((findChatUser.toLowerCase()))) {
        return chat;
      }
      return null;
    });
    this.setState(prevState => {
      return { ...prevState, findChatUser, filteredChatUserList };
    });
  };

  updateActiveChat(data, index) {
    const allChatItems = document.querySelectorAll('.chat-list .rce-citem');
    allChatItems.forEach(i => i.classList.remove('active'))
    allChatItems[index].classList.add('active');
    const activeChat = this.state.chats.filter(msg => msg.channel === data.channel)[0];
    this.getMessageList(activeChat.messages);
    this.setState(prevState => { return { ...prevState, activeChat } });
  }

  async sendNewMessage(event) {
    try {
      event.preventDefault();
      const idToken = await this.firebase.auth.currentUser.getIdToken(true);
      const rcvrUID = this.state.activeChat && this.state.activeChat.channel.split('chat_')[1].split('_').filter(u => u !== this.uid)[0];
      const message = {
        idToken,
        rcvrUID,
        msg: this.state.newMessage
      };
      this.socket.current.emit('sendMessage', { ...message }, async (message) => {
        const chats = this.state.chats && this.state.chats.map((c) => {
          if (c.channel === this.state.activeChat.channel) {
            c.messages.push(message);
          }
          return c;
        });

        const newMessage = '';
        const activeChat = chats.find(c => c.channel === this.state.activeChat.channel);
        this.getMessageList(activeChat.messages);

        const chatUserList = await this.getChatUserList(chats, this.uid);
        const filteredChatUserList = this.state.chatUserList.filter((chat) => {
          if (chat.title.toLowerCase().includes((this.state.findChatUser.toLowerCase()))) {
            return chat;
          }
          return null;
        });

        this.setState(prevState => {
          return { ...prevState, chats, activeChat, chatUserList, filteredChatUserList, newMessage };
        });
      });
    } catch (error) {
      toastAlert('error', 'Error while sedning a message!');
    }
  };

  async initChats() {
    try {
      const idToken = await this.firebase.auth.currentUser.getIdToken(true);
      const chats = await chatService.getAllChats({ idToken });
      const chatUserList = await this.getChatUserList(chats, this.uid);
      const filteredChatUserList = [...chatUserList];
      this.setState(prevState => {
        return { ...prevState, chats, chatUserList, filteredChatUserList };
      });
    } catch (error) {
      this.setState(prevState => {
        return { ...prevState, chats: [], chatUserList: [], filteredChatUserList: [] };
      });
      toastAlert('error', error.message || 'Error while fetching chats');
    } finally {
      document.body.classList.add('white-bg', 'dashboard');
      this.setLoading(false);
    }
  }

  componentDidMount() {
    this.socket.current = io(APP_SOCKET_URL);
    this.socket.current.emit('addUser', this.uid);
    this.socket.current.on('getUsers', async (onlineUsers) => {
      const chatUserList = await this.getChatUserList(this.state.chats, this.uid, onlineUsers);
      const filteredChatUserList = this.state.chatUserList.filter((chat) => {
        if (chat.title.toLowerCase().includes((this.state.findChatUser.toLowerCase()))) {
          return chat;
        }
        return null;
      });
      this.setState(prevState => { return { ...prevState, chatUserList, filteredChatUserList, onlineUsers } });
    });
    this.socket.current.on('getMessage', async (data) => {
      if (data && data.channel) {
        const chats = this.state.chats && this.state.chats.map((c) => {
          if (c.channel === data.channel) {
            c.messages.push(data.message);
          }
          return c;
        });

        const chatUserList = await this.getChatUserList(chats, this.uid);
        const filteredChatUserList = this.state.chatUserList.filter((chat) => {
          if (chat.title.toLowerCase().includes((this.state.findChatUser.toLowerCase()))) {
            return chat;
          }
          return null;
        });

        if (this.state.activeChat) {
          const activeChat = chats.find(c => c.channel === this.state.activeChat.channel);
          this.getMessageList(activeChat.messages);
          this.setState(prevState => { return { ...prevState, chats, activeChat, chatUserList, filteredChatUserList } });
        } else {
          this.setState(prevState => { return { ...prevState, chats, chatUserList, filteredChatUserList } });
        }
      }
    });
    this.initChats();
  }

  componentWillUnmount() {
    document.body.classList.remove('white-bg', 'dashboard');
    this.socket.current.emit('removeUser');
    this.setLoading(true);
  }

  render() {
    const { sidebarMenuOpen, findChatUser, filteredChatUserList, activeChat, messageList, newMessage } = this.state;
    return (
      <>
        <Header />
        <section>
          <div className="dashboard">
            <div id="wrapper" className={sidebarMenuOpen ? 'toggled' : ''}>
              <SidebarMenu toggleSidebarMenu={this.toggleSidebarMenu} />
              <div id="page-content-wrapper">
                <Container>
                  <Navbar className="mt-2">
                    <Button id="menu-toggle" onClick={this.toggleSidebarMenu} color="primary" className="navbar-toggler ml-n3 border-0 bg-transparent text-dark shadow-none">
                      <i className="fas fa-bars text-dak"></i>
                    </Button>
                  </Navbar>
                  <div className="d-flex page-content-dashboard mesage-box">
                    <div className="dashboard-subscribe-content w-100">
                      <Row className="m-0 p-0">
                        <Col lg="4" className="border-right">
                          <div className="chat-person-list">
                            <div className="search-chat-box">
                              <Input
                                type="text"
                                name="findChatUser"
                                className="form-control gry-input shadow-none"
                                id="findChatUser"
                                placeholder="Find people"
                                value={findChatUser}
                                onChange={(e) => this.fliteredChatUserList(e.target.value.trimStart())}
                              />
                            </div>
                            <ChatList
                              className="chat-list"
                              dataSource={filteredChatUserList}
                              onClick={this.updateActiveChat}
                            />
                          </div>
                        </Col>
                        <Col lg="8">
                          <div className={activeChat && activeChat.channel ? 'mesgs' : 'mesgs h-100'}>
                            {activeChat && activeChat.channel ? (
                              <>
                                <div className="msg_history">
                                  <MessageList
                                    className="message-list"
                                    lockable={false}
                                    toBottomHeight={'100%'}
                                    dataSource={messageList}
                                  />
                                  <div ref={this.scrollRef} />
                                </div>
                                <div className="type_msg">
                                  <Form
                                    className="input_msg_write"
                                    onSubmit={(e) => { this.sendNewMessage(e) }}
                                  >
                                    <Input
                                      type="text"
                                      className="write_msg"
                                      name="newMessage"
                                      value={newMessage}
                                      onChange={(e) => this.setNewMessage(e.target.value)}
                                      placeholder="Type a message"
                                    />
                                    <Button
                                      type="submit"
                                      className="msg_send_btn border-0 bg-transparent text-dark shadow-none p-2"
                                    >
                                      <svg xmlns="http://www.w3.org/2000/svg" width="20.571" height="17.662" viewBox="0 0 20.571 17.662">
                                        <path id="send" d="M1547.372,14831.092l17.346-7.334c.779-.34.6-.678-.068-1.129l-17.369-7.223c-.745-.227-.959.111-.869.678v5.3l7.28,1.693c.519.111.711.227.023.338l-7.3,1.8v4.967C1546.412,14831.318,1546.785,14831.432,1547.372,14831.092Z" transform="translate(-1545.527 -14814.469)" fill="none" stroke="#bec4ca" strokeWidth="1.73" fillRule="evenodd" />
                                      </svg>
                                    </Button>
                                  </Form>
                                </div>
                              </>) : (
                              <p className="open_conversation_msg">Open a conversation to start a chat.</p>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Container>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }
}

export default Message;